import React from 'react'
import { Tooltip } from 'antd'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { RootState } from '../../store'
import { createSelector } from '@reduxjs/toolkit'
import classNames from 'classnames'
import { IoChatboxEllipsesOutline } from 'react-icons/io5'
import { BsFillExclamationCircleFill } from 'react-icons/bs'
import { useIsMobile } from '../../utils/hooks/useIsMobile'

interface Props {
  messagesDrawerOpen: boolean
  toggleDrawerMessages: () => void
}

function CallControlsMessages({ messagesDrawerOpen, toggleDrawerMessages }: Props) {
  const { t } = useTranslation('room')
  const selectChatsDetails = (state: RootState) => state.chats.chatsDetails
  const newMessageSelector = createSelector(selectChatsDetails, (chatsDetails) => {
    let newMessage = false
    Object.values(chatsDetails).forEach((chat) => {
      if (chat.unreadMessagesCount > 0) newMessage = true
    })
    return newMessage
  })
  const newMessage = useSelector(newMessageSelector)
  const isMobile = useIsMobile()

  return (
    <Tooltip title={t('Messages')}>
      <div
        className={classNames({
          'call-control': !isMobile,
          'call-control-dropdown': isMobile,
          'call-control--active': messagesDrawerOpen,
        })}
        onClick={toggleDrawerMessages}
      >
        <IoChatboxEllipsesOutline size="1.8rem" color="#ffffff" />
        {newMessage && (
          <BsFillExclamationCircleFill
            fill="#f50"
            className="call-control-attention"
            strokeWidth={1}
          />
        )}
      </div>
    </Tooltip>
  )
}

export default CallControlsMessages
