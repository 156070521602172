import React from 'react'
import { Button, Tooltip } from 'antd'
import { MdOutlineFolderOff } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { setCreateDrive } from '../../features/Drives/redux/drivesSlice'

function DrivesEmpty() {
  const { t } = useTranslation('drives')
  const dispatch = useDispatch<AppDispatch>()
  const access = useSelector((state: RootState) => state.auth.access)

  return (
    <div className="Drives-empty-container">
      <MdOutlineFolderOff size="2.5em" />
      <h3>{t('You currently have no drives. Please click here to create one:')}</h3>
      <Tooltip
        title={
          !access?.organization
            ? t('You do not have the necessary rights.', { ns: 'common' })
            : ''
        }
      >
        <Button
          type="primary"
          onClick={() => dispatch(setCreateDrive(true))}
          disabled={!access?.organization}
        >
          {t('Create drive')}
        </Button>
      </Tooltip>
    </div>
  )
}

export default DrivesEmpty
