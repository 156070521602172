import React, { MutableRefObject } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { useTranslation } from 'react-i18next'
import { ProducerModel } from '../../features/room/redux/model'
import { Tooltip } from 'antd'
import RoomClient from '../../features/room/RoomClient'
import { AiOutlineFullscreen, AiOutlineFullscreenExit } from 'react-icons/ai'
import classNames from 'classnames'
import { toggleFullScreen } from '../../redux/appSlice'
import { useIsMobile } from '../../utils/hooks/useIsMobile'
function CallControlsFullscreen() {
  const { t } = useTranslation('room')
  const fullscreen = useSelector((state: RootState) => state.app.fullscreen)
  const isMobile = useIsMobile()
  const dispatch = useDispatch<AppDispatch>()

  return (
    <Tooltip title={fullscreen ? t('Exit full screen') : t('Full screen')}>
      <div
        className={classNames({
          'call-control': !isMobile,
        })}
        onClick={() => dispatch(toggleFullScreen())}
      >
        {fullscreen ? (
          <AiOutlineFullscreenExit size="1.8rem" color="#ffffff" />
        ) : (
          <AiOutlineFullscreen size="1.8rem" color="#ffffff" />
        )}
      </div>
    </Tooltip>
  )
}

export default CallControlsFullscreen
