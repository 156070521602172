import React, { ChangeEvent, RefObject } from 'react'
import { Form } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'
import { Trans, useTranslation } from 'react-i18next'
import { limitText } from '../../../utils/Utils'
import {
  addEncryptDriveFiles,
  setContextMenuVisible,
} from '../../../features/Drives/redux/drivesSlice'
import { filterFileListBySize, getFilesAndFoldersFromList } from '../DrivesService'
import { useToastContext } from '../../Toast/ToastContext'

interface Props {
  inputFile?: RefObject<HTMLInputElement>
  inputFolder?: RefObject<HTMLInputElement>
}

function DrivesFileUpload({ inputFile, inputFolder }: Props) {
  const dispatch = useDispatch<AppDispatch>()
  const { t } = useTranslation('drives')
  const auth = useSelector((state: RootState) => state.auth)
  const selectedDriveFolder = useSelector(
    (state: RootState) => state.drive.selectedDriveFolder,
  )
  const driveEncryptKey = useSelector(
    (state: RootState) =>
      state.drive.drives?.find((drive) => drive.id === selectedDriveFolder?.driveId)
        ?.fileEncryptKey,
  )

  const { ToastOpen } = useToastContext()
  /**
   *
   * @param e
   */
  const handleFilesChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setContextMenuVisible(false))
    e.preventDefault()
    if (e.target.files && selectedDriveFolder?.driveId && driveEncryptKey) {
      const { filesToAdd, filesToRemove } = filterFileListBySize(e.target.files)

      if (filesToAdd.length > 0 && auth.email && auth.firstName && auth.lastName) {
        dispatch(
          addEncryptDriveFiles({
            driveId: selectedDriveFolder?.driveId,
            folderId: selectedDriveFolder.id,
            driveEncryptKey,
            creatorEmail: auth.email,
            creatorFirstName: auth.firstName,
            creatorLastName: auth.lastName,
            files: filesToAdd,
            folders: [],
            filesBlocked: filesToRemove.length > 0,
            foldersBlocked: false,
          }),
        )
      }

      if (filesToRemove.length > 0) {
        filesToRemove.forEach((file) => {
          ToastOpen({
            message: t('FILE_TOO_LARGE', {
              ns: 'drives',
              shouldUnescape: true,
              name: limitText(file.name, 30),
              size: process.env.REACT_APP_DRIVE_SIZE_LIMIT_MEGA,
              components: { bold: <strong /> },
            }),
            type: 'error',
          })
        })
      }
    }
    e.target.value = ''
  }

  async function handleFoldersChange(e: ChangeEvent<HTMLInputElement>) {
    dispatch(setContextMenuVisible(false))
    e.preventDefault()
    if (e.target.files && selectedDriveFolder?.driveId && driveEncryptKey) {
      const { foldersToAdd, foldersToRemove, filesToRemove } = getFilesAndFoldersFromList(
        Array.from(e.target.files),
        selectedDriveFolder,
      )

      if (foldersToAdd.length > 0 && auth.email && auth.firstName && auth.lastName) {
        dispatch(
          addEncryptDriveFiles({
            driveId: selectedDriveFolder?.driveId,
            folderId: selectedDriveFolder.id,
            driveEncryptKey,
            creatorEmail: auth.email,
            creatorFirstName: auth.firstName,
            creatorLastName: auth.lastName,
            files: [],
            folders: foldersToAdd,
            filesBlocked: false,
            foldersBlocked: foldersToRemove.length > 0,
          }),
        )
      }

      if (filesToRemove.length > 0) {
        filesToRemove.forEach((file) => {
          ToastOpen({
            message: t('FILE_TOO_LARGE', {
              ns: 'drives',
              shouldUnescape: true,
              name: limitText(file.name, 30),
              size: process.env.REACT_APP_DRIVE_SIZE_LIMIT_MEGA,
              components: { bold: <strong /> },
            }),
            type: 'error',
          })
        })
      }

      if (foldersToRemove.length > 0) {
        foldersToRemove.forEach((folder) => {
          ToastOpen({
            message: (
              <Trans
                ns="drives"
                i18nKey="FOLDER_ALREADY_EXISTS"
                shouldUnescape={true}
                values={{
                  name: folder.name,
                }}
                components={{ bold: <strong /> }}
              />
            ),
            type: 'error',
          })
        })
      }
    }
    e.target.value = ''
  }

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }
  return (
    <>
      {inputFile && (
        <Form.Item getValueFromEvent={normFile} className="mb-0">
          <label>
            {t('Import file')}
            <input
              type="file"
              onChange={handleFilesChange}
              multiple
              style={{ display: 'none' }}
              ref={inputFile}
            />
          </label>
        </Form.Item>
      )}
      {inputFolder && (
        <Form.Item getValueFromEvent={normFile} className="mb-0">
          <label>
            {t('Import folder')}
            <input
              type="file"
              onChange={handleFoldersChange}
              multiple
              {...{
                webkitdirectory: '',
                directory: '',
              }}
              style={{ display: 'none' }}
              ref={inputFolder}
            />
          </label>
        </Form.Item>
      )}
    </>
  )
}

export default DrivesFileUpload
