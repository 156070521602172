import React, { createContext, useContext, useState, useMemo, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { UploadFile } from 'antd'
import { AppDispatch, RootState } from '../../store'
import {
  FolderInterface,
  fetchFolderById,
  setSelectedDriveFolder,
} from './redux/drivesSlice'
import { useNavigate } from 'react-router-dom'

interface DrivesContextProps {
  onSelectFolder: (row: FolderInterface) => void
  files: UploadFile<any>[] | undefined
  setFiles: React.Dispatch<React.SetStateAction<UploadFile<any>[] | undefined>>
}

interface DriveProviderProps {
  children: React.ReactNode
}

const DrivesContext = createContext<DrivesContextProps | undefined>(undefined)

export const useDrivesContext = () => {
  const context = useContext(DrivesContext)
  if (!context) {
    throw new Error('useDrivesContext must be used within a DriveProvider')
  }
  return context
}

const DriveProvider: React.FC<DriveProviderProps> = ({ children }) => {
  const [files, setFiles] = useState<UploadFile<any>[] | undefined>(undefined)
  const navigate = useNavigate()

  /**
   *
   * @param folder
   */
  const onSelectFolder = (folder: FolderInterface) => {
    navigate(`/drives/${folder.driveId}/folder/${folder.id}`)
  }

  const memoizedValue = useMemo(
    () => ({
      onSelectFolder,
      files,
      setFiles,
    }),
    [onSelectFolder, files, setFiles],
  )

  return <DrivesContext.Provider value={memoizedValue}>{children}</DrivesContext.Provider>
}

export default DriveProvider
