import React, { useState, MouseEvent } from 'react'
import { Button, Empty, Tooltip } from 'antd'
import { Trans, useTranslation } from 'react-i18next'
import { BsDownload } from 'react-icons/bs'
import { IoArrowBack, IoCloseOutline } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import {
  ContentTypes,
  resetSelectedFilesFolders,
} from '../../features/Drives/redux/drivesSlice'
import { AppDispatch, RootState } from '../../store'
import { downloadEncryptedFile, renderIcon } from '../../utils/Utils'
import './viewer.scss'
import { convertToPdf, idleViewer } from './ViewerSlice'
import classNames from 'classnames'
import useEventListener from '../../utils/hooks/useEventListener'
import { AiOutlineFilePdf } from 'react-icons/ai'

const SupportedIframeTypes = {
  PDF: ContentTypes.PDF,
  TXT: ContentTypes.TXT,
  MP4: ContentTypes.MP4,
  MP3: ContentTypes.MP3,
  CSV: ContentTypes.CSV,
  HTML: ContentTypes.HTML,
  CSS: ContentTypes.CSS,
  JS: ContentTypes.JS,
}

const SupportedImgTagTypes = {
  JPEG: ContentTypes.JPEG,
  JPG: ContentTypes.JPG,
  PNG: ContentTypes.PNG,
}

const ConvertibleToPdfTypes = {
  DOC: ContentTypes.DOC,
  DOCX: ContentTypes.DOCS,
  ODT: ContentTypes.ODT,
  XLS: ContentTypes.XLS,
  XLSX: ContentTypes.XLSX,
  ODS: ContentTypes.ODS,
  PPT: ContentTypes.PPT,
  PPTX: ContentTypes.PPTX,
  ODP: ContentTypes.ODP,
  RTF: ContentTypes.RTF,
  TXT: ContentTypes.TXT,
  CSV: ContentTypes.CSV,
}

interface Props {
  fullSize?: boolean
}

function Viewer({ fullSize }: Props) {
  const { t } = useTranslation('drives')
  const dispatch = useDispatch<AppDispatch>()
  const fileToView = useSelector((state: RootState) => state.viewer.fileToView)
  const convertStatus = useSelector(
    (state: RootState) => state.viewer.convertFileToPdfStatus,
  )
  const selectedDriveFolder = useSelector(
    (state: RootState) => state.drive.selectedDriveFolder,
  )
  const { id, name, mimeType } = fileToView?.file || {}
  const encryptKey = fileToView?.encryptKey

  useEventListener('keydown', (e: KeyboardEvent) => {
    if (fileToView && e.key === 'Escape') {
      resetViewer()
    }
  })

  const resetViewer = () => {
    dispatch(idleViewer())
    dispatch(resetSelectedFilesFolders())
  }

  const download = (e: MouseEvent) => {
    if (encryptKey && id) {
      downloadEncryptedFile(id, encryptKey, name)
      e.stopPropagation()
    }
  }

  const handleConvertToPdf = (e: MouseEvent) => {
    if (fileToView) {
      dispatch(
        convertToPdf({
          uid: fileToView.file.id || '',
          encryptKey: fileToView.encryptKey,
        }),
      )
      e.stopPropagation()
    }
  }

  if (!id || !name) return null

  const isDicom = mimeType === ContentTypes.DCM || name.toLowerCase().endsWith('.dcm')
  const isIframeType = mimeType && Object.values(SupportedIframeTypes).includes(mimeType)
  const isImageType = mimeType && Object.values(SupportedImgTagTypes).includes(mimeType)
  const isConvertible =
    mimeType && Object.values(ConvertibleToPdfTypes).includes(mimeType)

  const fileUrl = `${process.env.REACT_APP_FILE_ENCRYPTOR_V2_BASE_URL}/${id}/${encryptKey}`

  return (
    <div className={classNames('Viewer', { 'Viewer-fullSize': fullSize })}>
      <div className="Viewer-control-bar">
        <div className="Viewer-control-bar-left">
          <div className="action-rounded-icon" onClick={resetViewer}>
            <IoArrowBack size="1.5em" />
          </div>
        </div>
        <div className="Viewer-control-bar-center">
          <img
            className="file-icon mb-05rem"
            src={renderIcon(mimeType ?? '', name)}
            alt=""
          />
          <span className="title">{name}</span>
        </div>
        <div className="Viewer-control-bar-right">
          <Tooltip title={t('Download')} placement="bottom">
            <div className="action-rounded-icon" onClick={download}>
              <BsDownload size="1.5em" />
            </div>
          </Tooltip>
          <Tooltip title={t('Close', { ns: 'common' })} placement="bottom">
            <div className="action-rounded-icon" onClick={resetViewer}>
              <IoCloseOutline size="1.5em" />
            </div>
          </Tooltip>
        </div>
      </div>
      <div
        className={classNames('Viewer-wrapper', {
          'Viewer-wrapper-transparent': !isIframeType,
        })}
      >
        {isDicom && (
          <iframe
            src={`${window.location.origin}/u-dicom-viewer?drive_id=${selectedDriveFolder?.driveId}&file_id=${id}&filename=${name}&key=${encryptKey}`}
          />
        )}
        {isIframeType && <iframe src={fileUrl} title={name} />}
        {isImageType && <img src={fileUrl} alt={id} />}
        {!isIframeType && !isImageType && !isDicom && (
          <div className="unsupported">
            <h3>
              <Trans
                ns="drives"
                i18nKey="NOT_SUPPORTED_VIEWER"
                values={{ name: name.split('.').pop() }}
                components={{ bold: <strong /> }}
              />
            </h3>
            <Empty description="" />
            <div className="d-flex g-1rem">
              <Button type="default" icon={<BsDownload />} onClick={download}>
                {t('Download')}
              </Button>
              {isConvertible && (
                <Button
                  type="primary"
                  icon={<AiOutlineFilePdf />}
                  onClick={handleConvertToPdf}
                  loading={convertStatus === 'loading'}
                >
                  {convertStatus === 'loading' ? t('Converting...') : t('View in PDF')}
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Viewer
