import React, { MutableRefObject } from 'react'
import { Tooltip } from 'antd'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import RoomClient from '../../features/room/RoomClient'
import { RootState } from '../../store'
import { BsFilePerson } from 'react-icons/bs'

interface Props {
  roomClient: MutableRefObject<RoomClient>
}

function CallControlsBlurBackground({ roomClient }: Props) {
  const { t } = useTranslation('room')
  const bokehEffectActive = useSelector((state: RootState) => state.me.bokehEffectActive)

  return (
    <>
      {bokehEffectActive ? (
        <Tooltip title={t('Stop blurring background')}>
          <div
            className="call-control call-control--active"
            onClick={() => roomClient.current.toggleBokehEffect(false)}
          >
            <BsFilePerson size="1.8rem" color="#ffffff" />
          </div>
        </Tooltip>
      ) : (
        <Tooltip title={t('Blur background')}>
          <div
            className="call-control call-control--inactive"
            onClick={() => roomClient.current.toggleBokehEffect(true)}
          >
            <BsFilePerson size="1.8rem" color="#ffffff" />
          </div>
        </Tooltip>
      )}
    </>
  )
}

export default CallControlsBlurBackground
