import React from 'react'
import { Form, Select, Tooltip } from 'antd'
import { BiInfoCircle } from 'react-icons/bi'
import { RoomParticipantRoleEnum } from '../../../features/room/RoomInterface'
import { useTranslation } from 'react-i18next'

function RoleSelector() {
  const { t } = useTranslation('meetings')
  return (
    <Form.Item
      name="role"
      rules={[{ required: true, message: t('Choose a role please') }]}
    >
      <Select>
        <Select.Option value={RoomParticipantRoleEnum.Attendee}>
          <div className="d-flex d-flex-between d-flex-middle">
            <span className="mr-05rem">{t('Attendee')}</span>
            <Tooltip
              title={t('No access to documents', { ns: 'common' })}
              className="cursor-pointer"
            >
              <BiInfoCircle size="1em" className="info-color" />
            </Tooltip>
          </div>
        </Select.Option>
        <Select.Option value={RoomParticipantRoleEnum.Document}>
          <div className="d-flex d-flex-between d-flex-middle">
            <span className="mr-05rem">{t('Document')}</span>
            <Tooltip
              title={t('Access to documents during the meeting', { ns: 'common' })}
              className="cursor-pointer"
            >
              <BiInfoCircle size="1em" className="info-color" />
            </Tooltip>
          </div>
        </Select.Option>
        <Select.Option value={RoomParticipantRoleEnum.Proofreader}>
          <div className="d-flex d-flex-between d-flex-middle">
            <span className="mr-05rem">{t('Proofreader')}</span>
            <Tooltip
              title={t('Access to documents before, during and after the meeting', {
                ns: 'common',
              })}
              className="cursor-pointer"
            >
              <BiInfoCircle size="1em" className="info-color" />
            </Tooltip>
          </div>
        </Select.Option>
        <Select.Option value={RoomParticipantRoleEnum.Moderator}>
          <div className="d-flex d-flex-between d-flex-middle">
            <span className="mr-05rem">{t('Moderator')}</span>
            <Tooltip
              title={t('Can manage the meeting', { ns: 'common' })}
              className="cursor-pointer"
            >
              <BiInfoCircle size="1em" className="info-color" />
            </Tooltip>
          </div>
        </Select.Option>
      </Select>
    </Form.Item>
  )
}

export default RoleSelector
