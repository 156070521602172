import React, { useRef, useState } from 'react'
import classNames from 'classnames'
import { Trans, useTranslation } from 'react-i18next'
import './Room.scss'
import { Button, Dropdown, Modal, Tooltip } from 'antd'
import { MoreOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { notifyMissingParticipants } from '../Meetings/MeetingsSlice'
import { AppDispatch, RootState } from '../../store'
import { attemptDeleteParticipant } from './redux/roomSlice'
import './RoomParticipantsFilter.scss'
import { IoTrashOutline } from 'react-icons/io5'
import { useToastContext } from '../../components/Toast/ToastContext'

interface Participant {
  email: string
  firstName?: string
  lastName?: string
  displayName?: string
  role?: string
  status: 'pending' | 'online' | 'offline'
}

interface RoomParticipantsFilterProps {
  roomClient?: React.MutableRefObject<any>
  roomId?: string
}

export function ParticipantActions({
  participant,
  roomClient,
  status,
  setSelectedParticipant,
}: {
  participant: Participant
  roomClient?: React.MutableRefObject<any>
  status: 'pending' | 'online' | 'offline'
  setSelectedParticipant: (participant: Participant) => void
}) {
  const { t } = useTranslation('meetings')
  const { ToastDestroy } = useToastContext()

  const handleAction = (action: 'accept' | 'decline') => {
    if (roomClient?.current) {
      action === 'accept'
        ? roomClient.current.allowInvited(participant.email)
        : roomClient.current.prohibitInvited(participant.email)
      ToastDestroy(participant.email)
    }
  }

  return (
    <div className="room-participant-item-actions">
      {status === 'pending' && (
        <>
          <a onClick={() => handleAction('accept')}>{t('Accept')}</a>
          <Dropdown
            trigger={['click']}
            menu={{
              items: [
                {
                  key: 'decline',
                  label: <div className="error-color"> {t('Decline')}</div>,
                  onClick: () => {
                    handleAction('decline')
                  },
                  className: 'delete-btn',
                },
              ],
            }}
            placement="bottomRight"
          >
            <Button type="text" icon={<MoreOutlined />} className="circular-dots-btn" />
          </Dropdown>
        </>
      )}
      {status !== 'pending' && (
        <Dropdown
          trigger={['click']}
          menu={{
            items: [
              {
                key: 'remove',
                label: (
                  <div className="cursor-pointer d-flex d-flex-middle bin-button">
                    <IoTrashOutline size="1.1rem" className="error-color" />
                    <div className="dropdown-icon-margin-left error-color">
                      {t('Remove')}
                    </div>
                  </div>
                ),
                onClick: () => {
                  setSelectedParticipant(participant)
                },
                className: 'delete-btn',
              },
            ],
          }}
          placement="bottomRight"
        >
          <Button type="text" icon={<MoreOutlined />} className="circular-dots-btn" />
        </Dropdown>
      )}
    </div>
  )
}

export function ParticipantBlock({
  status,
  participants,
  roomClient,
  roomId,
  onBulkAction,
  setSelectedParticipant,
}: {
  status: 'pending' | 'online' | 'offline'
  participants: Participant[]
  roomClient?: React.MutableRefObject<any>
  roomId?: string
  onBulkAction: (action: 'accept' | 'decline' | 'notify', emails: string[]) => void
  setSelectedParticipant: (participant: Participant) => void
}) {
  const { t } = useTranslation('meetings')
  const emails = participants.map((p) => p.email)

  const bulkActions: Record<'pending' | 'online' | 'offline', JSX.Element | null> = {
    pending: (
      <div>
        <a className="decline" onClick={() => onBulkAction('decline', emails)}>
          {t('Decline all')}
        </a>
        <a onClick={() => onBulkAction('accept', emails)}>{t('Accept all')}</a>
      </div>
    ),
    online: null,
    offline: (
      <Tooltip title={t('Notify all offline participants by email.')} placement="topLeft">
        <a type="link" onClick={() => onBulkAction('notify', emails)}>
          {t('Notify all')}
        </a>
      </Tooltip>
    ),
  }

  const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)

  return (
    <div className="participants-block">
      <div className="participants-block-header">
        <strong>
          {t(capitalize(status), { ns: 'common' })} - {participants.length}
        </strong>
        {participants.length > 0 && bulkActions[status]}
      </div>
      {participants.map((participant) => {
        const displayName =
          participant.firstName && participant.lastName
            ? `${participant.firstName} ${participant.lastName}`
            : participant.displayName || participant.email

        return (
          <div className="room-participant-item" key={participant.email}>
            <div className="room-participant-item-body">
              <span
                className={classNames('room-participant-item-status-dot', {
                  'status-dot--pending': status === 'pending',
                  'status-dot--online': status === 'online',
                  'status-dot--offline': status === 'offline',
                })}
              />
              <Tooltip title={participant.email}>
                <div className="room-participant-item-body-email">{displayName}</div>
              </Tooltip>
            </div>
            <ParticipantActions
              status={status}
              participant={participant}
              roomClient={roomClient}
              setSelectedParticipant={setSelectedParticipant}
            />
          </div>
        )
      })}
    </div>
  )
}

export function RoomParticipantsFilter({
  roomClient,
  roomId,
}: RoomParticipantsFilterProps) {
  const { t } = useTranslation('meetings')
  const dispatch = useDispatch<AppDispatch>()
  const email = useSelector((state: RootState) => state.auth.email)

  const roomSelector = (state: RootState) => state.room
  const room = useSelector(roomSelector)
  const peers = useSelector((state: RootState) => state.room.peers)
  const { ToastDestroy } = useToastContext()

  const [filterType, setFilterType] = useState<'all' | 'pending' | 'online' | 'offline'>(
    'all',
  )
  const [selectedParticipant, setSelectedParticipant] = useState<Participant | null>(null)

  const connectedPeersEmails = Object.keys(peers)
  const waitingInvitedEmails = room.waitingInvited.map((i) => i.email)
  const otherParticipants =
    room?.participants?.filter(
      (p) =>
        !connectedPeersEmails.includes(p.email) &&
        !waitingInvitedEmails.includes(p.email),
    ) || []
  const otherInvited =
    room?.invited?.filter(
      (i) =>
        !connectedPeersEmails.includes(i.email) &&
        !waitingInvitedEmails.includes(i.email),
    ) || []

  const participantsList = [
    ...room.waitingInvited.map((w) => ({
      email: w.email,
      firstName: w.firstName,
      lastName: w.lastName,
      role: w.role,
      status: 'pending' as const,
    })),
    ...Object.entries(peers).map(([email, peer]) => ({
      email,
      displayName: peer.displayName,
      role: peers[email].role,
      status: 'online' as const,
    })),
    ...otherParticipants.map((p) => ({
      email: p.email,
      firstName: p.firstName,
      lastName: p.lastName,
      role: p.role,
      status: 'offline' as const,
    })),
    ...otherInvited.map((i) => ({
      email: i.email,
      firstName: i.firstName,
      lastName: i.lastName,
      role: i.role,
      status: 'offline' as const,
    })),
  ]

  const filterParticipants = (status?: 'pending' | 'online' | 'offline') =>
    participantsList
      .filter((p) => (status ? p.status === status : true))
      .filter((p) => p.email !== email)

  const handleBulkAction = (
    action: 'accept' | 'decline' | 'notify',
    emails: string[],
  ) => {
    if (roomClient?.current) {
      emails.forEach((email) => {
        if (action === 'accept') roomClient.current.allowInvited(email)
        if (action === 'decline') roomClient.current.prohibitInvited(email)
      })
      ToastDestroy()
    }
    if (action === 'notify' && roomId) {
      dispatch(notifyMissingParticipants({ roomId }))
    }
  }

  const handleDeleteParticipant = (email: string) => {
    dispatch(attemptDeleteParticipant(email))
  }

  const renderBlocks = () => {
    if (participantsList.length === 0) {
      return <div className="waiting-empty">{t('No participants were invited.')}</div>
    }

    const statuses =
      filterType === 'all' ? ['pending', 'online', 'offline'] : [filterType]

    const validBlocks = statuses
      .map((status) => {
        const participants = filterParticipants(
          status as 'pending' | 'online' | 'offline',
        )
        if (participants.length === 0) return null

        return (
          <ParticipantBlock
            key={status}
            status={status as 'pending' | 'online' | 'offline'}
            participants={participants}
            roomClient={roomClient}
            roomId={roomId}
            onBulkAction={handleBulkAction}
            setSelectedParticipant={setSelectedParticipant}
          />
        )
      })
      .filter(Boolean)

    if (validBlocks.length === 0) {
      const messages = {
        pending: t('No participants are waiting.'),
        online: t('No participants connected.'),
        offline: t('No participants were invited.'),
        all: t('No participants found.'),
      }

      return <div className="waiting-empty">{messages[filterType]}</div>
    }
    return <>{validBlocks}</>
  }

  return (
    <div className="RoomParticipantsFilter">
      <div className="filter-container">
        {['all', 'pending', 'online', 'offline'].map((type) => (
          <div
            key={type}
            className={classNames('filter-button', { active: filterType === type })}
            onClick={() => setFilterType(type as typeof filterType)}
          >
            {t(type.charAt(0).toUpperCase() + type.slice(1), { ns: 'common' })}
          </div>
        ))}
      </div>
      <div className="participants-list">{renderBlocks()}</div>
      <Modal
        title={t('Delete participant', { ns: 'room' })}
        okText={t('Delete', { ns: 'room' })}
        open={selectedParticipant != null}
        onOk={() => {
          if (selectedParticipant) {
            handleDeleteParticipant(selectedParticipant.email)
            setSelectedParticipant(null)
          }
        }}
        onCancel={() => setSelectedParticipant(null)}
        cancelText={t('Cancel', { ns: 'common' })}
      >
        {selectedParticipant && (
          <p>
            {selectedParticipant.firstName && selectedParticipant.lastName ? (
              <Trans
                i18nKey={'DELETE_PARTICIPANT'}
                shouldUnescape={true}
                ns="room"
                values={{
                  email: selectedParticipant.email,
                  name: `${selectedParticipant.firstName} ${selectedParticipant.lastName}`,
                }}
                components={{ bold: <strong /> }}
              />
            ) : (
              <Trans
                i18nKey={'DELETE_INVITED'}
                shouldUnescape={true}
                ns="room"
                values={{ email: selectedParticipant.email }}
                components={{ bold: <strong /> }}
              />
            )}
          </p>
        )}
      </Modal>
    </div>
  )
}
