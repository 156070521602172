import React, { MutableRefObject, useRef } from 'react'
import { Tooltip, Spin } from 'antd'
import { TbScreenShare, TbScreenShareOff } from 'react-icons/tb'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ModalConfirmation, { ModalConfirmationInterface } from '../Modal/ModalConfirmation'
import RoomClient from '../../features/room/RoomClient'
import { RootState } from '../../store'
import { ProducerModel } from '../../features/room/redux/model'
import { setPromotedPeer } from '../../features/room/redux/roomSlice'

interface Props {
  roomClient: MutableRefObject<RoomClient>
}

function CallControlsScreenShare({ roomClient }: Props) {
  const { t } = useTranslation('room')
  const dispatch = useDispatch()
  const enableShareConfirmation = useRef<ModalConfirmationInterface>(null)
  const consumers = useSelector((state: RootState) => state.consumers.consumers)
  const shareConsumer = Object.values(consumers).find(
    (consumer) => consumer.appData?.type === 'share',
  )
  const shareInProgress = useSelector((state: RootState) => state.me.shareInProgress)
  const shareProducer: ProducerModel | undefined = useSelector((state: RootState) =>
    Object.values(state.producers.producers).find(
      (producer) => producer.type === 'share',
    ),
  )
  let shareState = 'off'
  if (shareProducer) {
    shareState = 'on'
  }
  const startScreenShare = () => {
    if (!shareInProgress) {
      roomClient.current.enableShare()
    }
  }

  const enableShare = () => {
    if (shareConsumer) {
      enableShareConfirmation.current?.openModal()
    } else {
      startScreenShare()
    }
  }

  const disableShare = () => {
    endDisableShare()

    if (shareConsumer) {
      const peerId = shareConsumer.appData?.peerId
      if (peerId) {
        dispatch(setPromotedPeer(peerId))
      }
    } else {
      dispatch(setPromotedPeer(undefined))
    }
  }

  const endDisableShare = () => {
    if (!shareInProgress) {
      roomClient.current.disableShare()
    }
  }

  return (
    <>
      {shareState === 'on' ? (
        <Tooltip title={t('Stop sharing screen')}>
          <div className="call-control call-control--active" onClick={disableShare}>
            {shareInProgress ? (
              <Spin />
            ) : (
              <TbScreenShareOff size="1.8rem" color="#ffffff" strokeWidth={1} />
            )}
          </div>
        </Tooltip>
      ) : (
        <Tooltip title={t('Share screen')}>
          <div className="call-control call-control--inactive" onClick={enableShare}>
            {shareInProgress ? (
              <Spin />
            ) : (
              <TbScreenShare size="1.8rem" color="#ffffff" strokeWidth={1} />
            )}
          </div>
        </Tooltip>
      )}

      <ModalConfirmation
        ref={enableShareConfirmation}
        title={t('Screenshare already in progress')}
        onOk={startScreenShare}
        okText={t('Share', { ns: 'common' })}
        onCancel={() => enableShareConfirmation.current?.close()}
        cancelText={t('Cancel', { ns: 'common' })}
      >
        <p>
          {t(
            'Screen sharing is currently active. By sharing your own screen, it will automatically take centre stage.',
          )}
        </p>
      </ModalConfirmation>
    </>
  )
}

export default CallControlsScreenShare
