import React, { CSSProperties, useEffect, useRef } from 'react'
import { Form, Menu, MenuProps } from 'antd'
import {
  setContextMenuVisible,
  setOpenCreateFolder,
  setShareToTeamUrl,
} from '../../../features/Drives/redux/drivesSlice'
import { AppDispatch, RootState } from '../../../store'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useDriveLoader } from '../../../utils/hooks/UseDriveLoader'
import {
  MdDriveFolderUpload,
  MdOutlineCreateNewFolder,
  MdOutlineUploadFile,
} from 'react-icons/md'
import DrivesFileUpload from '../DrivesHeader/DrivesFileUpload'
import { ItemType } from 'antd/es/menu/interface'
import { AiOutlineCopy, AiOutlineShareAlt } from 'react-icons/ai'
import { copyClipboard } from '../DrivesService'
import { useNavigate } from 'react-router-dom'
import { LOGO } from '../../../assets/logos'
interface Props {
  coordinates: { x: number; y: number }
  itemsForRightClick?: ItemType[]
}

function DriveContextMenu({ coordinates, itemsForRightClick }: Props) {
  const { t } = useTranslation('drives')
  const dispatch = useDispatch<AppDispatch>()
  const { fileRights, folderRights, selectedDriveFolder } = useDriveLoader()
  const inputFile = useRef<HTMLInputElement>(null)
  const inputFolder = useRef<HTMLInputElement>(null)
  const itemsRightClickMenu: MenuProps['items'] = []
  const access = useSelector((state: RootState) => state.auth.access)
  const navigate = useNavigate()

  /**
   *
   */
  useEffect(() => {
    const handleClick = () => {
      dispatch(setContextMenuVisible(false))
    }
    window.addEventListener('click', handleClick)
    return () => {
      window.removeEventListener('click', handleClick)
    }
  }, [dispatch])

  if (selectedDriveFolder) {
    if (folderRights) {
      itemsRightClickMenu.push(
        {
          key: 'create_folder',
          className: 'menu-create-drive',
          label: (
            <Form.Item className="mb-0">
              <label>{t('Create folder')}</label>
            </Form.Item>
          ),
          onClick: () => dispatch(setOpenCreateFolder(true)),
          icon: <MdOutlineCreateNewFolder size="1rem" />,
        },
        {
          type: 'divider',
        },
      )
    }
    if (fileRights && folderRights) {
      itemsRightClickMenu.push({
        key: 'import_folder',
        className: 'menu-create-drive',
        label: (
          <span onClick={(event) => event.stopPropagation()}>
            <DrivesFileUpload inputFolder={inputFolder} />
          </span>
        ),
        icon: <MdDriveFolderUpload size="1rem" />,
        onClick: () => inputFolder.current?.click(),
      })
    }
    if (fileRights) {
      itemsRightClickMenu.push({
        key: 'import_file',
        className: 'menu-create-drive',
        label: (
          <span onClick={(event) => event.stopPropagation()}>
            <DrivesFileUpload inputFile={inputFile} />
          </span>
        ),

        icon: <MdOutlineUploadFile size="1rem" />,
        onClick: () => inputFile.current?.click(),
      })
    }
    itemsRightClickMenu.push({
      key: `copy_folder_url_${selectedDriveFolder.id}`,
      className: 'menu-create-drive',
      label: (
        <Form.Item className="mb-0">
          <label>{t('Copy Folder URL')}</label>
        </Form.Item>
      ),
      icon: <AiOutlineCopy size="1rem" />,
      onClick: () => {
        const folderURL = `${window.location.origin}/drives/${selectedDriveFolder.driveId}/folder/${selectedDriveFolder.id}`
        copyClipboard(folderURL)
      },
    })
    if (access?.team) {
      itemsRightClickMenu.push({
        key: `share_folder_url_${selectedDriveFolder.id}`,
        className: 'menu-create-drive',
        label: (
          <Form.Item className="mb-0">
            <label>{t('Share folder')}</label>
          </Form.Item>
        ),
        icon: <AiOutlineShareAlt size="1rem" />,
        extra: <img className="share-icon" src={LOGO.logo_auxateam} />,
        onClick: () => {
          const folderURL = `${window.location.origin}/drives/${selectedDriveFolder.driveId}/folder/${selectedDriveFolder.id}`
          dispatch(setShareToTeamUrl(folderURL))
          navigate('/messages')
        },
      })
    }
  }

  const items = itemsForRightClick ?? itemsRightClickMenu

  const menuStyle: CSSProperties = {
    position: 'absolute',
    backgroundColor: 'white',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
    borderRadius: '5px',
    padding: '5px',
    zIndex: 1000,
    left: coordinates.x,
    top: coordinates.y,
  }

  // TODO: Try to use ref
  const contextMenuWidth = document.getElementById('context-menu')?.clientWidth
  const contextMenuHeight = document.getElementById('context-menu')?.clientHeight
  if (contextMenuWidth && window.innerWidth - coordinates.x < contextMenuWidth) {
    menuStyle.left = window.innerWidth - contextMenuWidth - 10
  }

  if (contextMenuHeight && window.innerHeight - coordinates.y < contextMenuHeight) {
    menuStyle.top = window.innerHeight - contextMenuHeight - 10
  }

  return (
    <>
      {items.length > 0 && (
        <Menu
          id="context-menu"
          items={items}
          style={menuStyle}
          className="context-menu"
        />
      )}
    </>
  )
}

export default DriveContextMenu
