import React from 'react'
import { RootState } from '../../store'
import { useSelector } from 'react-redux'
import { Spin } from 'antd'
import { ServiceUnavailableError } from '../ServiceUnavailableError'
import { FolderInterface } from '../../features/Drives/redux/drivesSlice'
import DrivesHome from './DrivesHome/DrivesHome'
import DrivesHeader from './DrivesHeader/DrivesHeader'
import DriveListContent from './DriveListContent/DriveListContent'
import useFetchFolder from '../../utils/hooks/useFetchFolder'
import DrivesEmpty from './DrivesEmpty'
import { useFetchDriveRights } from '../../utils/hooks/useFetchDriveRights'

interface Props {
  adminRootFolders: FolderInterface[]
  sharedRootFolders: FolderInterface[]
  home?: boolean
}

function DrivesContent({ adminRootFolders, sharedRootFolders, home }: Props) {
  const fetchDrivesStatus = useSelector(
    (state: RootState) => state.drive.fetchDrivesStatus,
  )

  const drives = useSelector((state: RootState) => state.drive.drives)
  useFetchFolder()
  useFetchDriveRights()

  if (fetchDrivesStatus === 'loading') {
    return (
      <div className="d-flex d-flex-center d-flex-middle 2rem">
        <Spin size="large" />
      </div>
    )
  }

  if (
    fetchDrivesStatus === 'SERVICE_UNAVAILABLE' ||
    fetchDrivesStatus === 'unknown_error'
  ) {
    return <ServiceUnavailableError />
  }

  if (!drives || drives.length === 0) {
    return <DrivesEmpty />
  }

  return (
    <div className="Drives-main-container">
      {home ? (
        <DrivesHome
          adminRootFolders={adminRootFolders}
          sharedRootFolders={sharedRootFolders}
        />
      ) : (
        <>
          <DrivesHeader />
          <DriveListContent />
        </>
      )}
    </div>
  )
}

export default DrivesContent
