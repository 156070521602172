import React from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import './MessageInput.scss'
import { TFunction } from 'i18next'
import _ from 'lodash'

interface Props {
  // onSend: ({ text }: { text: string }) => void
  onSend: () => void
  onUpload: () => void
  t: TFunction
  currentChatId: string
  state: string
  setState: React.Dispatch<React.SetStateAction<string>>
  messageEditText?: string
  messageDraftText?: string
}

interface State {
  text: string
}

class MessageInputQuill extends React.Component<Props, State> {
  quillRef: React.RefObject<ReactQuill>
  modules = {
    toolbar: {
      container: '#message-input-toolbar',
      handlers: {
        send: () => this.props.onSend(),
        upload: () => {
          this.props.onUpload()
        },
      },
    },
    clipboard: {
      matchVisual: false,
    },
    keyboard: {
      bindings: {
        sendOnEnter: {
          key: 13,
          handler: () => this.props.onSend(),
        },
        newlineOnCtrlEnter: {
          key: 13,
          ctrlKey: true,
          handler: function (range: any) {
            let thisQuill: any = this
            thisQuill.quill.clipboard.dangerouslyPasteHTML(range.index, '<p></p>')
          },
        },
        newlineOnMajEnter: {
          key: 13,
          altKey: true,
          handler: function (range: any) {
            let thisQuill: any = this
            thisQuill.quill.clipboard.dangerouslyPasteHTML(
              range.index,
              '<p><br/><br/></p>',
            )
          },
        },
      },
    },
  }

  formats = ['bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet']

  constructor(props: Props) {
    super(props)
    this.state = { text: '' }
    this.quillRef = React.createRef<ReactQuill>()
  }

  focusAtEnd = () => {
    if (this.quillRef.current) {
      const quill = this.quillRef.current.getEditor()
      const length = quill.getLength()
      quill.setSelection(length, length)
    }
  }

  dangerouslyPasteHTML = (text: string | undefined) => {
    const quill = this.quillRef.current?.getEditor()
    quill?.clipboard.dangerouslyPasteHTML(text || '')
    this.focusAtEnd()
  }
  componentDidUpdate(prevProps: Props) {
    if (this.props.messageEditText != prevProps.messageEditText) {
      this.dangerouslyPasteHTML(this.props.messageEditText)
    }
    if (
      this.props.messageDraftText != prevProps.messageDraftText &&
      this.props.currentChatId !== prevProps.currentChatId
    ) {
      this.dangerouslyPasteHTML(this.props.messageDraftText)
    }
    if (this.props.t !== prevProps.t && this.quillRef.current) {
      //update placeholder
      //https://www.npmjs.com/package/react-quill#:~:text=placeholder%20%3A%20The%20default%20value
      this.quillRef.current.getEditor().root.dataset.placeholder =
        this.props.t('Enter your message')
    }
    this.quillRef.current?.getEditor().focus()
  }

  componentDidMount() {
    this.focusAtEnd()
  }

  render() {
    return (
      <ReactQuill
        ref={this.quillRef}
        onChange={this.props.setState}
        placeholder={this.props.t('Enter your message')} //The Quill API does not support changing this value dynamically.
        modules={this.modules}
        formats={this.formats}
        value={this.props.state}
        theme="snow"
      />
    )
  }
}

export default MessageInputQuill
