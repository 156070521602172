import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { fetchOrganizationSummary } from '../../features/Organisation/redux/organisationSlice'
import { SortableField } from '../../features/Groups/redux/groupsSlice'
import { SortOrder } from 'antd/es/table/interface'
import { fetchGroupParticipants } from '../../features/Groups/redux/groupsSlice'

interface GroupParticipantsTableState {
  sortField: SortableField
  sortOrder: SortOrder
}

type UseGroupParticipantsTableSorterParams = {
  groupId?: string
} & Partial<GroupParticipantsTableState>

const DEFAULT: GroupParticipantsTableState = {
  sortField: SortableField.LAST_NAME,
  sortOrder: 'ascend',
}

export default function useGroupParticipantsTableSorter({
  groupId,
  sortField = DEFAULT.sortField,
  sortOrder = DEFAULT.sortOrder,
}: UseGroupParticipantsTableSorterParams) {
  const dispatch = useDispatch<AppDispatch>()

  const organisationState = useSelector((state: RootState) => state.organisation)
  const organizationId = organisationState.organisation?.id

  const [groupTableState, setGroupTableState] = useState<GroupParticipantsTableState>({
    sortField,
    sortOrder,
  })

  const updateGroupTable = () => {
    if (!organizationId || !groupId) return

    const { sortField, sortOrder } = groupTableState
    dispatch(
      fetchGroupParticipants({
        organizationId,
        groupId,
        sortField,
        sortOrder,
      }),
    )
    dispatch(fetchOrganizationSummary({ organizationId }))
  }

  useEffect(() => {
    updateGroupTable()
  }, [groupTableState, organizationId, groupId, dispatch])

  return {
    groupTableState,
    setGroupTableState,
    updateGroupTable,
  }
}
