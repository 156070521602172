import React, { memo, useRef } from 'react'
import { Card, Dropdown, Button, Tooltip } from 'antd'
import './ApplicationCard.scss'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import {
  deleteFavorite,
  FavoriteInterface,
  setFavoriteToModify,
} from './redux/applicationsSlice'
import ModalConfirmation, {
  ModalConfirmationInterface,
} from '../../components/Modal/ModalConfirmation'
import { limitText } from '../../utils/Utils'
import { MenuProps } from 'antd/lib'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai'
import { InternApplicationInterface } from './Applications'
import { UseTruncateText } from '../../utils/hooks/UseTruncateText'
import { MdLock } from 'react-icons/md'

interface Props {
  application: FavoriteInterface | InternApplicationInterface
  intern: boolean
}

const COMING_SOON_APPS = ['Transfer', 'OtherAppInDevelopment']

function ApplicationCard({ application, intern }: Props) {
  const nameRef = useRef<HTMLHeadingElement>(null)
  const truncated = UseTruncateText(nameRef, application.name)

  const { t } = useTranslation('applications')
  const dispatch = useDispatch<AppDispatch>()
  const deleteFavoriteConfirmation = useRef<ModalConfirmationInterface>(null)
  const deleteFavoriteStatus = useSelector(
    (state: RootState) => state.applications.deleteFavoriteStatus,
  )

  const handleDeleteFavorite = () => {
    deleteFavoriteConfirmation.current?.openModal()
  }
  const confirmDeleteFavorite = () => dispatch(deleteFavorite({ id: application.id }))

  const isUnderDevelopment = (name: string) => {
    return COMING_SOON_APPS.includes(name)
  }

  function isInternApplication(
    application: FavoriteInterface | InternApplicationInterface,
  ): application is InternApplicationInterface {
    return (application as InternApplicationInterface).disabled !== undefined
  }

  const items: MenuProps['items'] = [
    {
      key: 'edit',
      label: (
        <div className="cursor-pointer link d-flex d-flex-middle">
          <AiOutlineEdit className="default-text-color" size="1.2rem" />
          <div className="dropdown-icon-margin-left">{t('Edit', { ns: 'common' })}</div>
        </div>
      ),
      onClick: () => dispatch(setFavoriteToModify(application as FavoriteInterface)),
    },
    {
      key: 'delete',
      label: (
        <div className="cursor-pointer link d-flex d-flex-middle">
          <AiOutlineDelete className="default-text-color" color="#ff4d4f" size="1.2rem" />
          <div className="dropdown-icon-margin-left error-color">
            {t('Delete', { ns: 'common' })}
          </div>
        </div>
      ),
      onClick: () => handleDeleteFavorite(),
    },
  ]

  const getTooltipText = () => {
    if (isUnderDevelopment(application.name)) {
      return t('Coming soon', { ns: 'common' })
    }
    if (isInternApplication(application) && application.disabled) {
      return t('You do not have the necessary rights.', { ns: 'common' })
    }
    if (truncated) {
      return application.name
    }
    return ''
  }

  return (
    <>
      <Tooltip title={getTooltipText()}>
        <a
          className="ApplicationCard"
          href={
            !isInternApplication(application) || !application.disabled
              ? application.url
              : undefined
          }
          target={!intern ? '_blank' : '_self'}
          rel="noreferrer"
        >
          <Card
            className={`card ${isInternApplication(application) && application.disabled ? 'card--disabled' : 'card--activate'}`}
            hoverable={!isInternApplication(application) || !application.disabled}
          >
            {application.icon ? (
              <img
                src={application.icon}
                alt={`${application.name} icon`}
                className={`logo ${intern ? 'logo-intern' : 'logo-extern'}`}
                onError={(e) => console.error(e)}
              />
            ) : (
              <div className="d-flex d-flex-center d-flex-middle">
                <h1 className="empty-favicon">
                  {Array.from(application.name)[0].toLocaleUpperCase()}
                </h1>
              </div>
            )}

            {isInternApplication(application) && application.disabled && (
              <div className="lock-overlay">
                <MdLock className="lock-icon" />
              </div>
            )}
          </Card>
          <h3 className="name" ref={nameRef}>
            {application.name}
          </h3>
          {!intern && (
            <div className="dropdown-container">
              <Dropdown menu={{ items }} trigger={['click']}>
                <Button
                  className="dropdown-toggle"
                  type="text"
                  icon={<BiDotsVerticalRounded size="1.5em" />}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                />
              </Dropdown>
            </div>
          )}
        </a>
      </Tooltip>

      <ModalConfirmation
        ref={deleteFavoriteConfirmation}
        title={t('Delete bookmark')}
        onOk={confirmDeleteFavorite}
        okText={t('Yes', { ns: 'common' })}
        confirmLoading={deleteFavoriteStatus === 'loading'}
        onCancel={() => deleteFavoriteConfirmation.current?.close()}
        cancelText={t('No', { ns: 'common' })}
      >
        <p>
          <Trans
            i18nKey={'DELETE_BOOKMARK'}
            shouldUnescape={true}
            ns="applications"
            values={{ name: limitText(application.name) }}
            components={{ bold: <strong /> }}
          />
        </p>
      </ModalConfirmation>
    </>
  )
}

export default memo(ApplicationCard)
