import { message } from 'antd'
import {
  DriveInterface,
  FileInterface,
  FolderInterface,
  selectableInterface,
} from '../../features/Drives/redux/drivesSlice'
import { UserGroup } from '../../features/auth/redux/authSlice'
import ApiError from '../../utils/errors/ApiError'
import { compareFilesFolders } from './DriveListContent/DriveListContent'
import i18next from 'i18next'

export interface Folder {
  name: string
  files: File[]
  subFolders: Folder[]
}

/**
 *
 * @param folder
 * @param newFolder
 * @param parentId
 * @returns
 */
export function addChildToFolder(
  folder: FolderInterface,
  newFolder: FolderInterface,
  parentId: string,
) {
  if (folder.id === parentId) {
    folder.childrenFolders.push(newFolder)
    folder.childrenFolders.sort((a, b) => a.name.localeCompare(b.name))
    return folder
  } else {
    if (folder.childrenFolders && folder.childrenFolders.length > 0) {
      for (const childFolder of folder.childrenFolders) {
        addChildToFolder(childFolder, newFolder, parentId)
      }
    }
  }
}

/**
 *
 * @param driveFolders
 * @param newFolder
 * @param parentId
 * @returns
 */
export function addDriveFolder(
  driveFolders: FolderInterface[],
  newFolder: FolderInterface,
  parentId: string,
) {
  for (const driveFolder of driveFolders) {
    if (driveFolder.driveId === newFolder.driveId) {
      addChildToFolder(driveFolder, newFolder, parentId)
    }
  }

  return driveFolders
}

/**
 * Update drive folders recursively
 * @param drives Array of DriveInterface
 * @param newFolder New folder to update
 * @returns Updated array of DriveInterface
 */
export function updateDriveFoldersRecursively(
  drives: DriveInterface[],
  newFolder: FolderInterface,
  keepExpansion?: boolean,
): DriveInterface[] {
  return drives.map((drive: DriveInterface) => {
    if (drive.id === newFolder.driveId) {
      const updatedRootFolder = updateFolderRecursively(
        drive.rootFolder,
        newFolder,
        keepExpansion,
      )
      if (updatedRootFolder !== drive.rootFolder) {
        return {
          ...drive,
          rootFolder: updatedRootFolder,
        }
      }
    }
    return drive
  })
}

/**
 * Update folder recursively
 * @param folder Folder to update
 * @param newFolder New folder data
 * @returns Updated folder if found, else the original folder
 */
export function updateFolderRecursively(
  folder: FolderInterface,
  newFolder: FolderInterface,
  keepExpansion?: boolean,
): FolderInterface {
  if (folder.id === newFolder.id) {
    return {
      ...folder,

      childrenFiles: keepExpansion
        ? newFolder.childrenFiles.map((file) => ({
            ...file,
            ...folder.childrenFiles.find((f) => f.id === file.id),
          }))
        : newFolder.childrenFiles,

      childrenFolders: keepExpansion
        ? newFolder.childrenFolders.map((dir) => ({
            ...dir,
            ...folder.childrenFolders.find((d) => d.id === dir.id),
          }))
        : newFolder.childrenFolders,
    }
  } else if (folder.childrenFolders && folder.childrenFolders.length > 0) {
    const updatedChildren = folder.childrenFolders.map((childFolder) =>
      updateFolderRecursively(childFolder, newFolder, keepExpansion),
    )
    if (updatedChildren !== folder.childrenFolders) {
      return {
        ...folder,
        childrenFolders: updatedChildren,
      }
    }
  }
  return folder
}

/**
 * remove drive folders recursively
 * @param drives Array of DriveInterface
 * @param deletedItem folder to delete
 * @returns Updated array of DriveInterface
 */
export function deleteDriveItemRecursively(
  drives: DriveInterface[],
  driveId: string,
  deletedItem: selectableInterface,
): DriveInterface[] {
  return drives.map((drive: DriveInterface) => {
    if (drive.id === driveId) {
      const deletedRootFolder = deletedItemRecursively([drive.rootFolder], deletedItem)
      if (deletedRootFolder !== drive.rootFolder.childrenFolders) {
        return {
          ...drive,
          rootFolder: deletedRootFolder[0],
        }
      }
    }
    return drive
  })
}

/**
 * delete folder recursively
 * @param childrenFolders of the current folder
 * @param itemToDelete file/folder to delete
 * @returns Updated folder if found, else the original folder
 */
export function deletedItemRecursively(
  childrenFolders: FolderInterface[],
  itemToDelete: selectableInterface,
): FolderInterface[] {
  if (childrenFolders && childrenFolders.length > 0) {
    const updatedChildren = childrenFolders
      .filter((childFolder) => !compareFilesFolders(childFolder, itemToDelete))
      .map((childFolder) => {
        return {
          ...childFolder,
          childrenFolders: deletedItemRecursively(
            childFolder.childrenFolders,
            itemToDelete,
          ),
          childrenFiles: childFolder.childrenFiles?.filter(
            (file) => !compareFilesFolders(file, itemToDelete),
          ),
        }
      })
    if (updatedChildren !== childrenFolders) {
      return updatedChildren
    }
  }
  return childrenFolders
}

/**
 *
 * @param drivefolders
 * @param newFolder
 * @returns
 */
export function mapDrives(
  drivefolders: DriveInterface[],
  callback: (folders: FolderInterface[]) => FolderInterface[],
): DriveInterface[] {
  return drivefolders.map((drive: DriveInterface) => {
    if (drive.rootFolder) {
      drive.rootFolder = callback([drive.rootFolder])[0]
      return drive
    } else {
      return drive
    }
  })
}

/**
 *
 * @param folders
 * @param selectedFolder
 * @param id
 * @returns
 */
export function findFolderById(
  folders: FolderInterface[],
  selectedFolder: FolderInterface,
  id: React.Key,
): FolderInterface | null {
  for (const folder of folders) {
    if (selectedFolder.driveId === folder.driveId) {
      if (folder.id === id) {
        return folder
      }

      if (folder.childrenFolders && folder.childrenFolders.length > 0) {
        const childFolder = findFolderById(folder.childrenFolders, selectedFolder, id)
        if (childFolder) {
          return childFolder
        }
      }
    }
  }
  return null
}

export function findParentFolderById(
  folders: FolderInterface[],
  id: React.Key,
  driveId: string,
): FolderInterface | null {
  function recursiveFind(
    parents: FolderInterface[],
    depth: number,
  ): FolderInterface | null {
    for (const folder of parents) {
      if (folder.driveId === driveId) {
        if (folder.childrenFolders) {
          for (const childFolder of folder.childrenFolders) {
            if (childFolder.id === id) {
              return folder
            }
            const parentFolder = recursiveFind([childFolder], depth + 1)
            if (parentFolder) {
              return parentFolder
            }
          }
        }
      }
    }
    return null
  }

  return recursiveFind(folders, 0)
}

export function filterFileSize(file: File) {
  const size = process.env.REACT_APP_DRIVE_SIZE_LIMIT_MEGA
    ? parseInt(process.env.REACT_APP_DRIVE_SIZE_LIMIT_MEGA, 10) * 1024 * 1024
    : 100 * 1024 * 1024
  return file.size <= size
}

export function filterFileListBySize(files: FileList) {
  const filesArray = Array.from(files)
  const filesToAdd = filesArray.filter((file: File) => file)
  const filesToRemove = filesArray.filter((file: File) => !filterFileSize(file))

  return {
    filesToAdd,
    filesToRemove,
  }
}

export function getFilesAndFoldersFromList(
  itemsList: File[],
  selectedFolder: FolderInterface,
) {
  const foldersToAdd: Folder[] = []
  const foldersToRemove: Folder[] = []
  const filesToRemove: File[] = []

  const getFolderInList = (folders: Folder[], name: string) => {
    const elmt = folders.find((folder) => folder.name === name)
    if (elmt) {
      return elmt
    } else {
      const newElement = {
        name,
        files: [],
        subFolders: [],
      }
      folders.push(newElement)
      return newElement
    }
  }

  const buildTreeFromPath = (file: File) => {
    const pathParts = file.webkitRelativePath.split('/')
    if (
      pathParts.length > 1 &&
      selectedFolder.childrenFolders.some((fold) => fold.name === pathParts[0])
    ) {
      if (!foldersToRemove.some((fold) => fold.name === pathParts[0])) {
        foldersToRemove.push({ name: pathParts[0], files: [], subFolders: [] })
      }
      return
    }

    pathParts.pop()
    let currentFolderList = foldersToAdd
    let currentFolder = null
    for (const path of pathParts) {
      currentFolder = getFolderInList(currentFolderList, path)
      currentFolderList = currentFolder.subFolders
    }
    currentFolder?.files.push(file)
  }

  itemsList.forEach((item) => {
    if (filterFileSize(item)) {
      buildTreeFromPath(item)
    } else {
      filesToRemove.push(item)
    }
  })

  return { foldersToAdd, foldersToRemove, filesToRemove }
}

export async function getFilesAndFolders(
  itemsList: DataTransferItemList,
  selectedFolder: FolderInterface,
) {
  const filesToAdd: File[] = []
  const foldersToAdd: Folder[] = []
  const filesToRemove: File[] = []
  const foldersToRemove: Folder[] = []

  async function traverseDirectory(entry: FileSystemEntry, parentFolder?: Folder) {
    if (entry.isDirectory) {
      const directoryReader = (entry as FileSystemDirectoryEntry).createReader()
      const entries = await readEntries(directoryReader)

      const customFolder = { name: entry.name, files: [], subFolders: [] } as Folder
      if (parentFolder) {
        parentFolder.subFolders.push(customFolder)
      } else {
        foldersToAdd.push(customFolder)
      }

      await Promise.all(
        entries.map((subEntry) => traverseDirectory(subEntry, customFolder)),
      )
    } else {
      const fileEntry = entry as FileSystemFileEntry
      const file = await readFile(fileEntry)
      const targetArray = parentFolder ? parentFolder.files : filesToAdd

      if (filterFileSize(file)) {
        targetArray.push(file)
      } else {
        filesToRemove.push(file)
      }
    }
  }

  async function readEntries(
    directoryReader: FileSystemDirectoryReader,
  ): Promise<FileSystemEntry[]> {
    return new Promise((resolve, reject) => {
      directoryReader.readEntries(resolve, reject)
    })
  }

  async function readFile(fileEntry: FileSystemFileEntry): Promise<File> {
    return new Promise((resolve, reject) => {
      fileEntry.file(resolve, reject)
    })
  }

  const itemsArray = Array.from(itemsList)
  const promises: Promise<void>[] = []
  itemsArray.forEach((item) => {
    if (item.kind === 'file') {
      const entry = item.webkitGetAsEntry()
      if (entry) {
        promises.push(traverseDirectory(entry as FileSystemEntry))
      }
    }
  })

  try {
    await Promise.all(promises)
    return { foldersToAdd, filesToAdd, filesToRemove, foldersToRemove }
  } catch (error) {
    console.error('Error:', error)
    return { foldersToAdd: [], filesToAdd: [], filesToRemove: [], foldersToRemove: [] }
  }
}

export function isDriveAdmin(
  drive?: DriveInterface,
  userEmail?: string,
  userGroups?: UserGroup[],
): boolean {
  if (!drive || !userEmail) {
    return false
  }

  if (drive.creatorEmail === userEmail) {
    return true
  }

  const user = drive.users.find((u) => u.email === userEmail && u.adminRights)
  const group = drive.groups.find(
    (g) => userGroups?.map((gr) => gr.id).includes(g.id) && g.adminRights,
  )
  return !!user || !!group
}

export interface SearchDriveContentDTO {
  files: FileInterface[]
  folders: FolderInterface[]
}

interface SearchDriveContentArgs {
  jwt?: string
  q: string
}

export async function search({
  jwt,
  q,
}: SearchDriveContentArgs): Promise<SearchDriveContentDTO> {
  const response = await fetch(`${process.env.REACT_APP_BASE_DRIVE_URL}/search`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${jwt}`,
    },
    body: JSON.stringify({ q }),
  })

  const result = await response.json()

  if (!response.ok) {
    throw new ApiError(response.status.toString())
  }

  return result
}

export function copyClipboard(url: string) {
  navigator.clipboard
    .writeText(url)
    .then(() => message.success(i18next.t('URL copied to clipboard', { ns: 'common' })))
    .catch(() => message.error(i18next.t('Failed to copy URL', { ns: 'common' })))
}
