export enum ServicePermission {
  TEAM = 'team',
  MEETINGS = 'meetings',
  PLANNING = 'planning',
  DRIVE = 'drive',
  ORGANIZATION = 'organization',
}

export function hasPermission(
  access: { [key in ServicePermission]?: boolean },
  requiredServicePermission: ServicePermission[],
): boolean {
  return requiredServicePermission.every((permission) => access[permission] === true)
}
