import { useTranslation } from 'react-i18next'
import {
  RoomInterface,
  RoomParticipant,
  RoomParticipantRoleEnum,
} from '../../../features/room/RoomInterface'
import React, { useRef, useState } from 'react'
import { Option } from '../../DrivesComponent/CreateDrive/DriveFormAddParticipant'
import { Button, Form, InputRef, Modal } from 'antd'
import { IoAddCircle } from 'react-icons/io5'
import { meetingColor } from '../RoomManage'
import ParticipantSearch from './ParticipantSearch'
import RoleSelector from './RoleSelector'
import { useAddParticipant } from '../../../utils/hooks/useAddParticipant'
import { KnownParticipants } from './AddParticipantForm'

interface AddParticipantFormProps {
  room?: RoomInterface
  addParticipant: (payload: RoomParticipant) => any
  addInvited: (values: any) => any
}
//TODO: Refactore and use AddParticipantForm
function AddParticipantModal({
  room,
  addParticipant,
  addInvited,
}: AddParticipantFormProps) {
  const { t } = useTranslation('meetings')
  const [addParticipantForm] = Form.useForm()
  const [options, setOptions] = useState<Option[]>([])
  const [addParticipantOpen, setAddParticipantOpen] = useState(false)
  const inputFocusRef = useRef<InputRef>(null)
  const [knownPeers, dispatchKnownPeers] = useState<KnownParticipants>({})

  const { onAddParticipant } = useAddParticipant({
    room,
    addParticipant,
    addInvited,
    options,
    setOptions,
    form: addParticipantForm,
    knownPeers,
  })

  function onCancel() {
    setAddParticipantOpen(false)
    addParticipantForm.resetFields()
  }

  function onOpen(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault()
    e.stopPropagation()
    setAddParticipantOpen(true)
  }

  return (
    <>
      <Button
        className="add-button"
        type="primary"
        icon={<IoAddCircle size="1.5rem" color="white" />}
        onClick={onOpen}
      >
        {t('Add', { ns: 'common' })}
      </Button>
      <Modal
        centered
        open={addParticipantOpen}
        title={t('Add participant')}
        onOk={() => addParticipantForm.submit()}
        onCancel={onCancel}
        okText={t('Add', { ns: 'common' })}
        cancelText={t('Cancel', { ns: 'common' })}
        maskClosable={false}
        width="35em"
        afterOpenChange={(open) =>
          open && inputFocusRef.current?.focus({ cursor: 'all' })
        }
      >
        <div className="add-modal">
          <Form
            form={addParticipantForm}
            initialValues={{
              role: RoomParticipantRoleEnum.Attendee,
              color: meetingColor[0].value,
            }}
            name="basic"
            onFinish={onAddParticipant}
            layout="vertical"
            autoComplete="off"
            disabled={room?.archived}
            scrollToFirstError={true}
          >
            <ParticipantSearch
              room={room}
              form={addParticipantForm}
              options={options}
              setOptions={setOptions}
              knownPeers={knownPeers}
              dispatchKnownPeers={dispatchKnownPeers}
            />
            <div className="mt-1rem">
              <RoleSelector />
            </div>
          </Form>
        </div>
      </Modal>
    </>
  )
}

export default AddParticipantModal
