import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useToastContext } from '../../components/Toast/ToastContext'
import { useTranslation } from 'react-i18next'

const useRedirectToast = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { ToastOpen } = useToastContext()
  const { t } = useTranslation('common')

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const redirected = params.get('redirected') === 'true'

    if (redirected) {
      ToastOpen({
        message: t(
          'You have been redirected because you do not have the necessary rights to access this service.',
        ),
        type: 'warning',
      })

      params.delete('redirected')
      navigate({ search: params.toString() }, { replace: true })
    }
  }, [location.search, navigate, ToastOpen, t])
}

export default useRedirectToast
