import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import jwtDecode from 'jwt-decode'
import { FetchStatus } from '../../../utils/FetchStatus'
import ApiError from '../../../utils/errors/ApiError'

interface State {
  connectStatus: FetchStatus
  jwt?: string
  email?: string
  roomId?: string
  roomToken?: string
  sessionExpiresAt?: number
}

export const initialState: State = {
  connectStatus: 'idle',
}

/**
 * Connect invited.
 */
export const connectInvited = createAsyncThunk(
  'invite/connect',
  async ({ token }: { token: string }) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/users/invite/login`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token }),
      },
    )

    const body = await response.json()
    if (!response.ok) {
      const message = body.message || 'unknown_error'
      throw new ApiError(response.status.toString(), body, message)
    }

    const decodedJwt = jwtDecode(body.token) as any
    const meetingId = decodedJwt['entity']
    const email = decodedJwt['sub']

    return { token: body.token, meetingId, email, roomToken: token }
  },
)

export const removeInvited = createAsyncThunk(
  'invite/invitedHistory/remove',
  async ({ jwt, service, email }: { jwt: string; service: string; email: string }) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/users/invite/invitedHistory/remove`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${jwt}`,
        },
        body: JSON.stringify({ service, email }),
      },
    )

    if (!response.ok) {
      throw new Error('Could not remove guest')
    }
  },
)

export const inviteSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearInvite: (state) => {
      state.connectStatus = 'idle'
      state.jwt = undefined
      state.email = undefined
      state.roomId = undefined
      state.roomToken = undefined
      state.sessionExpiresAt = undefined
    },
    idleConnectStatus: (state) => {
      state.connectStatus = 'idle'
    },
  },

  extraReducers: {
    [connectInvited.pending.type]: (state) => {
      state.connectStatus = 'loading'
      state.jwt = undefined
      state.email = undefined
      state.roomId = undefined
      state.roomToken = undefined
      state.sessionExpiresAt = undefined
    },
    [connectInvited.fulfilled.type]: (
      state,
      {
        payload,
      }: PayloadAction<{
        token: string
        meetingId: string
        email: string
        roomToken: string
      }>,
    ) => {
      const decoded: any = jwtDecode(payload.token)
      state.connectStatus = 'success'
      state.jwt = payload.token
      state.email = payload.email
      state.roomId = payload.meetingId
      state.roomToken = payload.roomToken
      state.sessionExpiresAt = decoded.exp * 1000
    },
    [connectInvited.rejected.type]: (state, action) => {
      const error = action.error
      state.connectStatus = error.name === 'ApiError' ? error.message : 'UNKNOWN_ERROR'
      state.jwt = undefined
      state.email = undefined
      state.roomId = undefined
      state.roomToken = undefined
      state.sessionExpiresAt = undefined
    },
  },
})

export const { clearInvite, idleConnectStatus } = inviteSlice.actions

export default inviteSlice.reducer
