import { useDispatch } from 'react-redux'
import {
  RoomInterface,
  RoomParticipant,
  RoomParticipantRole,
  RoomParticipantRoleEnum,
} from '../../features/room/RoomInterface'
import { AppDispatch } from '../../store'
import { useToastContext } from '../../components/Toast/ToastContext'
import { Dispatch, SetStateAction, useState } from 'react'
import { attemptAddParticipant } from '../../features/room/redux/roomSlice'
import { meetingColor } from '../../components/RoomManage/RoomManage'
import { Option } from '../../components/RoomManage/ManageParticipants/ParticipantSearch'
import { FormInstance } from 'antd'
import { KnownParticipants } from '../../components/RoomManage/ManageParticipants/AddParticipantForm'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'

interface Props {
  room?: RoomInterface
  addParticipant: (payload: RoomParticipant) => any
  addInvited: (values: any) => any
  options: Option[]
  setOptions: Dispatch<SetStateAction<Option[]>>
  form: FormInstance
  knownPeers: KnownParticipants
}

export function useAddParticipant({
  room,
  addParticipant,
  addInvited,
  options,
  setOptions,
  form,
  knownPeers,
}: Props) {
  const dispatch = useDispatch<AppDispatch>()
  const { ToastOpen } = useToastContext()
  const { t } = useTranslation('meetings')

  const onAddParticipant = (values: {
    newParticipant: string
    role: RoomParticipantRole
  }) => {
    const newParticipant = values.newParticipant.trim().toLowerCase()

    if (newParticipant === room?.creator?.email) {
      return
    }

    const alreadyAdded =
      room?.participants?.some((participant) => participant.email === newParticipant) ||
      room?.invited?.some((invited) => invited.email === newParticipant)

    if (alreadyAdded) {
      ToastOpen({
        message: t('This participant has already been added to the meeting'),
        type: 'error',
      })
      return
    }

    if (
      values.role === RoomParticipantRoleEnum.Moderator &&
      !knownPeers[newParticipant]
    ) {
      ToastOpen({
        message: t('A moderator must be a user of your organisation.'),
        type: 'warning',
      })
      return
    }

    if (knownPeers[newParticipant]) {
      dispatch(
        addParticipant({
          email: newParticipant,
          firstName: knownPeers[newParticipant].firstName,
          lastName: knownPeers[newParticipant].lastName,
          role: values.role,
          color: meetingColor[0].value,
        }),
      )
    } else {
      dispatch(
        addInvited({
          email: newParticipant,
          role: values.role,
        }),
      )
    }

    dispatch(
      attemptAddParticipant({
        email: newParticipant,
        role: values.role,
        color: meetingColor[0].value,
      }),
    )

    form.resetFields(['role', 'newParticipant'])

    const updatedOptions = options.filter((option) =>
      option.options.filter((opt) => opt.value !== newParticipant),
    )

    setOptions(updatedOptions)
  }

  return { onAddParticipant }
}
