import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useParams } from 'react-router'
import { RootState } from '../../store'
import { ServicePermission, hasPermission } from '../auth/ServicePermission'
import { useToastContext } from '../../components/Toast/ToastContext'
import { useTranslation } from 'react-i18next'

function RoomRequireAuth({
  children,
  requiredServicePermission,
  invitedAccepted,
}: {
  children: any
  requiredServicePermission?: ServicePermission[]
  invitedAccepted?: boolean
}) {
  const username = useSelector(
    (state: RootState) => state.auth.email || state.invite.email,
  )
  const { inviteToken, roomId, page } = useParams()
  const jwt = useSelector((state: RootState) => state.auth.jwt)
  const access = useSelector((state: RootState) => state.auth.access)

  // If the user is not authenticated and there is no `inviteToken`.
  if (!username && !inviteToken) {
    const redirectPath = page
      ? `/login/${roomId}/${page}`
      : roomId
        ? `/login/${roomId}`
        : '/login'
    return <Navigate to={redirectPath} replace />
  }

  // If the user is not authenticated but has an `inviteToken
  else if (!username && inviteToken) {
    return <Navigate to={`/invite/${inviteToken}`} replace />
  }

  // If the user is authenticated, but does not have the required permissions
  if (
    jwt &&
    access &&
    requiredServicePermission &&
    !hasPermission(access, requiredServicePermission)
  ) {
    return <Navigate to="/applications?redirected=true" />
  }

  // If the user is authenticated and authorised, display the content
  return children
}

export default RoomRequireAuth
