import React, { MutableRefObject, Ref, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import PeerView, { PeerViewRefType } from './PeerView'
import { ProducerModel } from './redux/model'
import './Me.scss'
import classNames from 'classnames'
import RoomClient from './RoomClient'
import PeerViewInfo from './PeerViewInfo'
import CallQualityIndicator from '../../components/CallQualityIndicator'

interface Props {
  roomClient: MutableRefObject<RoomClient>
}

function Me({ roomClient }: Props) {
  const me = useSelector((state: RootState) => state.me)
  const auth = useSelector((state: RootState) => state.auth)
  const invite = useSelector((state: RootState) => state.invite)
  const bokehEffectActive = useSelector((state: RootState) => state.me.bokehEffectActive)
  const peerViewRef = useRef<PeerViewRefType>(null)
  const [videoResolutionWidth, setVideoResolutionWidth] = useState(0)
  const [videoResolutionHeight, setVideoResolutionHeight] = useState(0)

  const videoProducer: ProducerModel | undefined = useSelector((state: RootState) =>
    Object.values(state.producers.producers).find(
      (producer) => producer.type === 'back' || producer.type === 'front',
    ),
  )
  const videoVisible = videoProducer && !videoProducer.paused ? true : false

  const audioProducer: ProducerModel | undefined = useSelector((state: RootState) =>
    Object.values(state.producers.producers).find(
      (producer) => producer.type === 'audio',
    ),
  )

  const shareProducer: ProducerModel | undefined = useSelector((state: RootState) =>
    Object.values(state.producers.producers).find(
      (producer) => producer.type === 'share',
    ),
  )
  const networkInfoVisible = useSelector(
    (state: RootState) => state.room.networkInfoVisible,
  )

  useEffect(() => {
    if (peerViewRef.current?.canvas.current) {
      roomClient.current.setOutputCanvasRef(
        peerViewRef.current?.canvas,
        peerViewRef.current?.video,
      )
    }

    return () => {
      roomClient.current.setOutputCanvasRef(undefined, undefined)
    }
  }, [peerViewRef.current?.canvas.current])

  let displayName = '??'
  if (auth.jwt) {
    displayName = `${auth.firstName} ${auth.lastName}`.trim()
  } else if (invite.jwt) {
    displayName = invite.email || '??'
  }

  return (
    <div
      className={classNames({
        Peer: true,
        'Peer--share': videoProducer?.type === 'share',
      })}
    >
      <PeerView
        isMe
        isMyWebcam={videoProducer ? videoProducer.type !== 'share' : false}
        me={me}
        displayName={displayName}
        audioTrack={audioProducer ? audioProducer.track : null}
        audioTrackPaused={audioProducer ? audioProducer.paused : undefined}
        videoTrack={videoProducer ? videoProducer.track : null}
        videoVisible={videoVisible}
        videoScore={videoProducer ? videoProducer.score : null}
        shareTrack={shareProducer ? shareProducer.track : null}
        ref={peerViewRef}
        showCanvas={bokehEffectActive}
        onResolutionChange={(width, height) => {
          setVideoResolutionWidth(width)
          setVideoResolutionHeight(height)
        }}
      />

      <PeerViewInfo
        audioProducer={audioProducer}
        videoProducer={videoProducer}
        videoResolutionWidth={videoResolutionWidth}
        videoResolutionHeight={videoResolutionHeight}
        onChangeMaxSendingSpatialLayer={(spatialLayer) => {
          roomClient.current.setMaxSendingSpatialLayer(spatialLayer)
        }}
        isMe={true}
      />

      {networkInfoVisible && (
        <CallQualityIndicator
          audioProducer={audioProducer}
          videoProducer={videoProducer}
          isMe={true}
          roomClient={roomClient}
        />
      )}
    </div>
  )
}

export default Me
