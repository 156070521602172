import React, { useEffect, useRef } from 'react'
import Layout from '../../components/Layout/Layout'
import { Card, Spin } from 'antd'
import './Applications.scss'
import { useTranslation } from 'react-i18next'
import { LOGO } from '../../assets/logos'
import ApplicationCard from './ApplicationCard'
import { HiOutlineViewGridAdd } from 'react-icons/hi'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import {
  fetchFavorites,
  idleDeleteFavoriteStatus,
  setAddFavoriteModalVisible,
} from './redux/applicationsSlice'
import AddFavoriteModal from './AddFavoriteModal'
import { ServiceUnavailableError } from '../../components/ServiceUnavailableError'
import { useAttemptsListener } from 'auxasphere-react-kit'
import { useToastContext } from '../../components/Toast/ToastContext'
import UpdateFavoriteModal from './UpdateFavoriteModal'
import HomeNewFeaturesTour from '../NewFeaturesTour/HomeNewFeaturesTour'
import useDesktopNotification from '../../utils/hooks/useDesktopNotification'
import useRedirectToast from '../../utils/hooks/useRedirectToast'

export interface InternApplicationInterface {
  id: string
  name: string
  icon: string
  url: string
  disabled: boolean
}

function Applications() {
  const { t } = useTranslation('applications')
  const dispatch = useDispatch<AppDispatch>()
  const access = useSelector((state: RootState) => state.auth.access)
  const favorites = useSelector((state: RootState) => state.applications.favorites)
  const { ToastOpen } = useToastContext()
  useDesktopNotification()
  useRedirectToast()

  const baseUrl = window.location.origin

  const fetchFavoritesStatus = useSelector(
    (state: RootState) => state.applications.fetchFavoritesStatus,
  )

  const deleteFavoriteStatus = useSelector(
    (state: RootState) => state.applications.deleteFavoriteStatus,
  )

  /**
   *
   * @param endpoint
   */
  function buildUrl(endpoint: string) {
    return `${baseUrl}${endpoint}`
  }

  const apps: InternApplicationInterface[] = []
  apps.push({
    id: 'Meetings',
    name: 'Meetings',
    icon: LOGO.logo_auxameetings,
    url: buildUrl('/upcoming-meetings'),
    disabled: !access?.meetings,
  })

  apps.push({
    id: 'Planning',
    name: 'Planning',
    icon: LOGO.logo_auxaplanning,
    url: buildUrl('/calendar'),
    disabled: !access?.planning,
  })

  apps.push({
    id: 'Team',
    name: 'Team',
    icon: LOGO.logo_auxateam,
    url: buildUrl('/messages'),
    disabled: !access?.team,
  })
  apps.push({
    id: 'Drive',
    name: 'Drive',
    icon: LOGO.logo_auxadrive,
    url: buildUrl('/drives/home'),
    disabled: !access?.drive,
  })

  apps.push({
    id: 'Transfer',
    name: 'Transfer',
    icon: LOGO.logo_auxatransfer,
    url: '',
    disabled: true,
  })

  const newFeaturesTourTitleRef = useRef(null)
  const newFeaturesTourFavoritesRowRef = useRef(null)
  const newFeaturesTourAddFavoriteRef = useRef(null)

  useEffect(() => {
    dispatch(fetchFavorites())
  }, [])

  useAttemptsListener(
    [
      [
        deleteFavoriteStatus,
        {
          success: () => {
            ToastOpen({
              message: t('Favorite was successfully deleted.'),
              type: 'success',
            })
          },
          unknown_error: () => {
            ToastOpen({
              message: t('Error deleting favorite.'),
              type: 'error',
            })
          },
        },
      ],
    ],
    () => dispatch(idleDeleteFavoriteStatus()),
  )

  return (
    <Layout
      footer={true}
      background={true}
      content={
        <div className="applications">
          <div className="applications-content applications-content--desktop">
            <div className="row-container" ref={newFeaturesTourTitleRef}>
              <h1 className="title">{t('My Auxa solutions')}</h1>
              <div className="row">
                {apps.map((app, index) => (
                  <div key={index}>
                    <ApplicationCard application={app} intern={true} />
                  </div>
                ))}
              </div>
            </div>
            <div className="row-container" ref={newFeaturesTourFavoritesRowRef}>
              <h1 className="title">{t('My workspace')}</h1>
              {fetchFavoritesStatus === 'success' && (
                <>
                  <div className="row">
                    {favorites?.map((favorite, index) => (
                      <div key={index}>
                        <ApplicationCard application={favorite} intern={false} />
                      </div>
                    ))}
                    <div
                      className="ApplicationCard"
                      onClick={() => dispatch(setAddFavoriteModalVisible(true))}
                      ref={newFeaturesTourAddFavoriteRef}
                    >
                      <Card className="card card--activate" hoverable>
                        <HiOutlineViewGridAdd size="5em" className="add mt-0_2rem" />
                      </Card>
                      <h3 className="name">{t('Add', { ns: 'common' })}</h3>
                    </div>
                  </div>

                  <HomeNewFeaturesTour
                    titleRef={newFeaturesTourTitleRef}
                    favoritesRowRef={newFeaturesTourFavoritesRowRef}
                    addFavoriteRef={newFeaturesTourAddFavoriteRef}
                  />
                </>
              )}

              {fetchFavoritesStatus === 'loading' && (
                <div className="ml-2rem d-flex d-flex-middle h-3rem">
                  <Spin size="large" />
                </div>
              )}

              {(fetchFavoritesStatus === 'SERVICE_UNAVAILABLE' ||
                fetchFavoritesStatus === 'unknown_error') && <ServiceUnavailableError />}
            </div>
          </div>
          <AddFavoriteModal />
          <UpdateFavoriteModal />
        </div>
      }
    />
  )
}

export default Applications
