import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
  fetchFolderById,
  idleFetchFolderStatus,
} from '../../features/Drives/redux/drivesSlice'
import { AppDispatch, RootState } from '../../store'
import { useToastContext } from '../../components/Toast/ToastContext'
import { useAttemptsListener } from 'auxasphere-react-kit'
import { useTranslation } from 'react-i18next'

const useFetchFolder = () => {
  const { t } = useTranslation('drives')
  const { driveId, folderId } = useParams()
  const dispatch = useDispatch<AppDispatch>()

  const { ToastOpen } = useToastContext()

  const fetchFolderStatus = useSelector(
    (state: RootState) => state.drive.fetchFolderStatus,
  )
  const navigate = useNavigate()

  useAttemptsListener([
    [
      fetchFolderStatus,
      {
        NOT_ENOUGH_RIGHT: () => {
          ToastOpen({
            message: t('You do not have the necessary rights to open this folder.'),
            type: 'error',
          })
          navigate('/drives/home')
        },
        FETCH_ERROR: () => {
          ToastOpen({
            message: t('The drive service is not available at the moment.'),
            type: 'error',
            duration: 50,
          })
          navigate('/drives/home')
        },
        unknown_error: () => {
          ToastOpen({
            message: t('Error opening folder.'),
            type: 'error',
          })
        },
      },
      () => dispatch(idleFetchFolderStatus()),
    ],
  ])

  useEffect(() => {
    if (driveId && folderId) {
      dispatch(
        fetchFolderById({
          id: folderId,
          driveId: driveId,
          selected: true,
        }),
      )
    }
  }, [driveId, folderId, dispatch])
}

export default useFetchFolder
