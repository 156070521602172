import React, { useContext, useRef, useState } from 'react'
import { Button, Dropdown, Form, Input, InputRef, Modal, Tooltip, List } from 'antd'
import { useTranslation } from 'react-i18next'
import { ChatWebSocketConnectionContext } from '../WebsocketConnection/ChatWebSocketConnectionContext'
import './CreateChat.scss'
import ModifyChatPeers from './ModifyChatPeers'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'
import {
  SearchGroupDTO,
  SearchUserDTO,
} from '../../../features/Organisation/OrganisationService'
import { IoAddCircle } from 'react-icons/io5'
import { Chat, setCurrentChatId } from '../Chat/chatsSlice'
import { getChatDetails } from '../../../features/room/RoomChat/ChatLibrary'
import { normalizeString } from '../Chat/chatService'
import VirtualList from 'rc-virtual-list'

interface Props {
  squadId: string
  inCall?: boolean
}

function CreateChat({ squadId, inCall }: Props) {
  const { t } = useTranslation('chat')
  const [form] = Form.useForm()
  const ws = useContext(ChatWebSocketConnectionContext)
  const [createChatOpen, setCreateChatOpen] = useState(false)
  const [createPeerConversationOpen, setCreatePeerConversationOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const inputTitleRef = useRef<InputRef>(null)
  const access = useSelector((state: RootState) => state.auth.access)

  const chats = useSelector((state: RootState) => state.chats.chats[squadId]) || []
  const dispatch = useDispatch<AppDispatch>()

  const auth = useSelector((state: RootState) => state.auth)
  const defaultPeersToAdd: SearchUserDTO[] = [
    {
      email: auth.email || '',
      firstName: auth.firstName || '',
      lastName: auth.lastName || '',
      organizationRole: auth.organizationRole,
    },
  ]
  const [peersToAdd, setPeersToAdd] = useState<SearchUserDTO[]>(defaultPeersToAdd)
  const [groupsToAdd, setGroupsToAdd] = useState<SearchGroupDTO[]>([])

  /**
   *
   * @param param0
   */
  function onSubmit({ title }: { title: string }) {
    ws?.sendCreateChat(
      title,
      squadId,
      peersToAdd.map((p) => p.email),
      groupsToAdd.map((g) => g.id),
    )
    form.setFieldsValue({
      title: '',
    })
    setCreateChatOpen(false)
    setPeersToAdd(defaultPeersToAdd)
  }

  /**
   *
   */
  function onOpenCreateChannel() {
    setCreateChatOpen(true)
  }

  /**
   *
   */
  function onOpenCreateConversation() {
    setCreatePeerConversationOpen(true)
  }

  /**
   *
   */
  function onCancel() {
    setCreateChatOpen(false)
    setCreatePeerConversationOpen(false)
    form.setFieldsValue({
      title: '',
    })
    setPeersToAdd(defaultPeersToAdd)
    setSearchQuery('')
  }

  /**
   *
   */
  function selectChat(chatId: string) {
    dispatch(setCurrentChatId(chatId))
    setCreatePeerConversationOpen(false)
  }

  const items = [
    {
      key: 'new_channel',
      label: t('New discussion channel'),
      onClick: onOpenCreateChannel,
    },
  ]
  if (!inCall) {
    items.unshift({
      key: 'new_conversation',
      label: t('New private conversation'),
      onClick: onOpenCreateConversation,
    })
  }

  const filteredChats = chats.filter((c) => {
    const { name } = getChatDetails(c)
    const qNormalized = normalizeString(searchQuery)
    const nameNormalized = normalizeString(name)
    return nameNormalized.includes(qNormalized) && c.peerToPeer && !c.archived
  })

  return (
    <>
      {auth.email && auth.firstName && auth.lastName && (
        <>
          <div className="menu-action-btn-container">
            <Dropdown menu={{ items }} trigger={['click']}>
              <Tooltip
                title={
                  !access?.organization
                    ? t('You do not have the necessary rights.', { ns: 'common' })
                    : ''
                }
              >
                <Button
                  className="menu-action-btn"
                  type="primary"
                  icon={<IoAddCircle size="1.5rem" color="white" />}
                  disabled={!access?.organization}
                >
                  {t('New discussion')}
                </Button>
              </Tooltip>
            </Dropdown>
          </div>

          <Modal
            title={t('Create a discussion channel')}
            open={createChatOpen}
            onCancel={onCancel}
            onOk={() => form.submit()}
            okText={t('Create', { ns: 'common' })}
            centered
            afterOpenChange={(open) => open && inputTitleRef.current?.focus()}
            width="70em"
          >
            <div className="chat-modal">
              <Form form={form} layout="vertical" onFinish={onSubmit}>
                <Form.Item
                  name="title"
                  label={t('Discussion channel name')}
                  className="chat_create_title_input"
                  rules={[
                    {
                      required: true,
                      message: t('Please input a discussion channel name'),
                    },
                  ]}
                >
                  <Input ref={inputTitleRef} />
                </Form.Item>
                <label>
                  {t('Search and choose users or groups to add', { ns: 'drives' })}
                </label>
              </Form>
              <ModifyChatPeers
                peers={peersToAdd}
                groups={groupsToAdd}
                onUsersUpdate={setPeersToAdd}
                onGroupsUpdate={setGroupsToAdd}
              />
            </div>
          </Modal>

          <Modal
            title={t('Create a new private conversation')}
            open={createPeerConversationOpen}
            onCancel={onCancel}
            centered
            width="50em"
            okButtonProps={{ style: { display: 'none' } }}
            afterOpenChange={(open) => open && inputTitleRef.current?.focus()}
          >
            <div className="chat-modal">
              <Input
                className="mb-1em"
                ref={inputTitleRef}
                placeholder={t('Search a user')}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                allowClear
              />
              <List className="select_chat_list virtual-list-participant">
                <VirtualList data={filteredChats} itemKey="id">
                  {(chat) => {
                    const { name, icon, backgroundColor } = getChatDetails(chat)
                    const fullName =
                      chat.peer?.firstName && chat.peer?.lastName
                        ? `${chat.peer.firstName} ${chat.peer.lastName}`
                        : chat.peer?.email
                    const description =
                      chat.peer?.firstName && chat.peer?.lastName
                        ? chat.peer.email
                        : t('Guest without account', { ns: 'common' })

                    return (
                      <List.Item key={chat.id} onClick={() => selectChat(chat.id)}>
                        <List.Item.Meta
                          avatar={
                            <div
                              className="chat_icon chat_icon--large"
                              style={{ backgroundColor }}
                            >
                              {icon}
                            </div>
                          }
                          title={fullName}
                          description={description}
                        />
                      </List.Item>
                    )
                  }}
                </VirtualList>
              </List>
            </div>
          </Modal>
        </>
      )}
    </>
  )
}

export default CreateChat
