import React from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'antd'
import classNames from 'classnames'
import { useIsMobile } from '../../utils/hooks/useIsMobile'
import { CgMenuGridO } from 'react-icons/cg'

function CallControlsMenu() {
  const { t } = useTranslation('room')
  const isMobile = useIsMobile()

  return (
    <Tooltip title={t('Menu (new tab)', { ns: 'layout' })}>
      <a
        className={classNames({
          'call-control': !isMobile,
        })}
        href="/applications"
        target="_blank"
      >
        <CgMenuGridO size="2rem" color="#ffffff" />
      </a>
    </Tooltip>
  )
}

export default CallControlsMenu
