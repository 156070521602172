import React, { useState } from 'react'
import './DrivesSearch.scss'
import { AutoComplete, Form, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import { search } from './DrivesService'
import { AppDispatch, RootState } from '../../store'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { renderIcon } from '../../utils/Utils'
import { AiOutlineFolder } from 'react-icons/ai'
import { setFileToView, ViewFile } from '../Viewer/ViewerSlice'
import { FileInterface, FolderInterface } from '../../features/Drives/redux/drivesSlice'
import { SearchOutlined } from '@ant-design/icons'
import { useDrivesContext } from '../../features/Drives/DrivesContext'

interface SelectOption {
  value: string
  label: React.ReactNode
  type: 'file' | 'folder'
  info: FileInterface | FolderInterface
}

function DrivesSearch() {
  const { t } = useTranslation('drives')
  const [options, setOptions] = useState<SelectOption[]>([])
  const jwt = useSelector((state: RootState) => state.auth.jwt)
  const dispatch = useDispatch<AppDispatch>()
  const drives = useSelector((state: RootState) => state.drive.drives)
  const [searchForm] = Form.useForm()
  const { onSelectFolder } = useDrivesContext()

  /**
   *
   * @param value
   * @param option
   */
  function doOnSelect(value: string, option: SelectOption) {
    setOptions([])

    if (option.type === 'file') {
      const fileInterface = option.info as FileInterface
      const encryptKey = drives?.find(
        (drive) => drive.id === fileInterface.driveId,
      )?.fileEncryptKey
      if (encryptKey) {
        const file = {
          id: fileInterface.id,
          name: fileInterface.name,
          size: Number(fileInterface.size),
          mimeType: fileInterface.mimeType,
        } as ViewFile
        dispatch(setFileToView({ file, encryptKey }))
      }
    }

    if (option.type === 'folder') {
      onSelectFolder(option.info as FolderInterface)
    }
    searchForm.resetFields(['search'])
  }

  /**
   *
   * @param searchText
   */
  const onSearch = async (q: string) => {
    setOptions([])

    try {
      const searchResult = await search({ jwt, q })

      const fileSelectOptions: SelectOption[] = searchResult.files.map((file) => ({
        value: file.name,
        label: (
          <div className="drive-option-item">
            <div className="option-info">
              <img src={renderIcon(file.mimeType, file.name)} className="file-icon" />
              <div className="option-name">
                <span>{file.name}</span>
                <small>{file.creatorFullName}</small>
              </div>
            </div>
            <span className="option-date">
              {dayjs(file.createdAt).format('DD/MM/YYYY')}
            </span>
          </div>
        ),
        type: 'file',
        info: file,
      }))

      const folderSelectOptions: SelectOption[] = searchResult.folders.map((folder) => ({
        value: folder.name,
        label: (
          <div className="drive-option-item">
            <div className="option-info">
              <AiOutlineFolder size="1.7em" color="#757575" />
              <div className="option-name">
                <span>{folder.name}</span>
                <small>{folder.creatorFullName}</small>
              </div>
            </div>
            <span className="option-date">
              {dayjs(folder.createdAt).format('DD/MM/YYYY')}
            </span>
          </div>
        ),
        type: 'folder',
        info: folder,
      }))

      // Combine both file and folder options
      const allOptions: SelectOption[] = [...fileSelectOptions, ...folderSelectOptions]

      setOptions(allOptions)
    } catch (err) {
      console.error('Search failed:', err)
    }
  }

  return (
    <div className="DrivesSearch">
      <div className="autocomplete-container">
        <Form name="searchForm" form={searchForm}>
          <Form.Item style={{ marginBottom: '0px' }} name="search">
            <AutoComplete
              allowClear
              options={options}
              onSelect={doOnSelect}
              onSearch={onSearch}
            >
              <Input
                addonBefore={<SearchOutlined />}
                placeholder={t('Search in my drives')}
              />
            </AutoComplete>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default DrivesSearch
