import React from 'react'
import { Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { useIsMobile } from '../../utils/hooks/useIsMobile'
import classNames from 'classnames'
import { IoSettingsOutline } from 'react-icons/io5'

interface Props {
  settingDrawerOpen: boolean
  toggleDrawerSettings: () => void
}

function CallControlsSettings({ settingDrawerOpen, toggleDrawerSettings }: Props) {
  const { t } = useTranslation('room')
  const isMobile = useIsMobile()

  return (
    <Tooltip title={t('Settings')}>
      <div
        className={classNames({
          'call-control': !isMobile,
          'call-control-dropdown': isMobile,
          'call-control--active': settingDrawerOpen,
        })}
        onClick={toggleDrawerSettings}
      >
        <IoSettingsOutline size="1.8rem" color="#ffffff" />
      </div>
    </Tooltip>
  )
}

export default CallControlsSettings
