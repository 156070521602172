import React from 'react'
import { Divider } from 'antd'
import _ from 'lodash'
import DrivesBreadcrumb from './DrivesBreadcrumb'
import { useDriveLoader } from '../../../utils/hooks/UseDriveLoader'

function DrivesHeader() {
  const { selectedDriveFolder } = useDriveLoader()

  return (
    <>
      {selectedDriveFolder && (
        <>
          <div className="layout-header-bottom drive-header">
            <h4 className="layout-list-header">
              <div className="tree-mobile-container">
                <DrivesBreadcrumb />
              </div>
            </h4>
          </div>
          <Divider />
        </>
      )}
    </>
  )
}

export default DrivesHeader
