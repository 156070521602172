import React, { useEffect, useState } from 'react'
import { Spin, Tree } from 'antd'
import type { DirectoryTreeProps, EventDataNode } from 'antd/es/tree'
import { useDispatch, useSelector } from 'react-redux'
import { MdOutlineFolderShared } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import { FolderTreeData } from './DrivesTree'
import {
  fetchFolderById,
  FolderInterface,
} from '../../../../features/Drives/redux/drivesSlice'
import { AppDispatch, RootState } from '../../../../store'
import { findFolderById } from '../../DrivesService'
import DriveRightsIcon from '../../DriveRightsIcon'

const { DirectoryTree } = Tree

interface Props {
  folder: FolderTreeData
  drivesFolders: FolderInterface[]
  selectedFolder: FolderInterface | undefined
  onSelectFolder: (folder: FolderInterface) => void
  expandedKeys: React.Key[]
  setExpandedKeys: (key: React.Key[]) => void
  manageDrive: boolean
}

function DriveTreeItem({
  folder,
  drivesFolders,
  manageDrive,
  selectedFolder,
  onSelectFolder,
  expandedKeys,
  setExpandedKeys,
}: Props) {
  const dispatch = useDispatch<AppDispatch>()
  const [loadingKey, setLoadingKey] = useState<React.Key>()

  /**
   *
   * @param keys
   * @param info
   */
  const onExpand: DirectoryTreeProps['onExpand'] = async (keys, info) => {
    const nodeInfo = info.node as EventDataNode<FolderInterface>
    if (nodeInfo.active == false) {
      const folder = findFolderById(drivesFolders, nodeInfo, nodeInfo.key)
      if (folder) {
        if (
          folder.childrenFolders &&
          folder.childrenFolders.length > 0 &&
          typeof folder.childrenFolders[0] === 'string'
        ) {
          try {
            setLoadingKey(nodeInfo.key)
            await dispatch(fetchFolderById({ id: folder.id, driveId: folder.driveId }))
          } finally {
            setLoadingKey(undefined)
          }
        }
      }
      setExpandedKeys(keys)
    }
  }

  /**
   *
   * @param keys
   * @param info
   */
  const onSelect: DirectoryTreeProps['onSelect'] = (keys, info) => {
    const nodeInfo = info.node as EventDataNode<FolderInterface>

    const folder = findFolderById(drivesFolders, nodeInfo, nodeInfo.key)
    if (folder) {
      onSelectFolder(folder)
    }
  }

  return (
    <DirectoryTree
      onExpand={onExpand}
      onSelect={onSelect}
      titleRender={(nodeData: any) => (
        <>
          {nodeData.root ? (
            <span className="DrivesMenu-drive-title-container">
              <span className="title-drive">{nodeData.title}</span>
              {manageDrive && (
                <span className="group-icon">
                  <DriveRightsIcon driveId={nodeData.driveId} />
                </span>
              )}
            </span>
          ) : (
            <span className="folder-title-container">
              {loadingKey == nodeData.key && <Spin size="small" />}
              <span className="title">{nodeData.title}</span>
            </span>
          )}
        </>
      )}
      treeData={[
        {
          ...folder,
          icon: (
            <MdOutlineFolderShared className="DrivesMenu-drive-title-icon" size="1.2em" />
          ),
        },
      ]}
      selectedKeys={selectedFolder ? [selectedFolder?.id] : []}
      expandedKeys={expandedKeys}
    />
  )
}

export default DriveTreeItem
