import React, { useEffect, useState } from 'react'
import {
  Divider,
  Form,
  Row,
  Input,
  Col,
  DatePicker,
  InputNumber,
  Button,
  Modal,
  Tree,
  ColorPicker,
  Tooltip,
  Checkbox,
  Alert,
  Space,
  Select,
} from 'antd'
import TextAreaEditor from '../TextAreaEditor/TextAreaEditor'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import TimeZoneVisualizer from '../TimeZoneVisualizer/TimeZoneVisualizer'
import DocumentsUpload from '../DocumentsUpload/DocumentsUpload'
import DocumentsList from '../DocumentsList/DocumentsList'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import {
  idleUpdateRoomStatus,
  setRoomToModify,
  setRoomToModifyConfirm,
  updateRoom,
  addParticipant,
  addInvited,
  deleteInvited,
  deleteParticipant,
  updateParticipantRole,
  updateInvitedRole,
  addUnconfirmedFile,
  removeUnconfirmedFile,
  RoomToModify,
  setRoomToDelete,
  idleDeleteRoomStatus,
  deleteDateRoomToModify,
  addDateRoomToModify,
  idleUpdatePlanRoomStatus,
  updatePlanRoom,
  isRoomToModifyPending,
  RoomToModifyPending,
  fetchPeriodicRoom,
  updatePeriodicRoom,
  idleFetchPeriodicRoomStatus,
  idleupdatePeriodicRoomStatus,
  idleDeleteGeneratedRoomStatus,
  idleDeletePeriodicRoomStatus,
  isRoomModifyPeriodic,
} from '../../features/Meetings/MeetingsSlice'
import { Trans, useTranslation } from 'react-i18next'
import dayjs, { Dayjs } from 'dayjs'
import { RoomFile, RoomType } from '../../features/room/RoomInterface'
import { AiOutlineDelete } from 'react-icons/ai'
import ManageParticipants from './ManageParticipants/ManageParticipants'
import '../../features/Meetings/meetings.scss'
import useDatesTreeOptions from '../../utils/hooks/useDatesTreeOptions'
import { useAttemptsListener } from 'auxasphere-react-kit'
import { BiInfoCircle } from 'react-icons/bi'
import '../../index.scss'
import TagReference from '../../features/Meetings/NewMeeting/TagReference'
import {
  FormValues,
  defaultByWeekDay,
  defaultFrequency,
  getDatePickerLocale,
} from '../../features/Meetings/NewMeeting/NewMeetingForm'
import PeriodicityForm from '../../features/Meetings/PeriodicityForm'
import { Periodicity } from '../../features/Meetings/NewMeetingSlice'
import { RRule } from 'rrule'
import { unescape } from 'html-escaper'
import { setUpdateMeetingDrawerOpen } from '../../features/calendar/redux/calendarSlice'
import { useToastContext } from '../Toast/ToastContext'
import { hasRoomAdminRights } from '../../utils/Utils'

//TODO move to auxasphere-react-kit
export const meetingColor = [
  { label: 'primaryColor', value: '#224160' },
  { label: 'tomato', value: '#D50000' },
  { label: 'flamingo', value: '#E67C73' },
  { label: 'tangerine', value: '#F4511E' },
  { label: 'banana', value: '#F6BF26' },
  { label: 'sage', value: '#33B679' },
  { label: 'basil', value: '#0B8043' },
  { label: 'peacock', value: '#039BE5' },
  { label: 'blueberry', value: '#3F51B5' },
  { label: 'lavender', value: '#7986CB' },
  { label: 'grape', value: '#8E24AA' },
  { label: 'graphite', value: '#616161' },
]
interface Props {
  room: RoomToModify
  close: () => void
  showTitle?: boolean
  fromCalendar?: boolean
}

function RoomManage({ room, close, showTitle, fromCalendar }: Props) {
  const { t, i18n } = useTranslation('meetings')
  const dispatch = useDispatch<AppDispatch>()
  const { ToastOpen } = useToastContext()
  const [isChecked, setChecked] = useState(false)
  const email = useSelector((state: RootState) => state.auth.email)
  const roomToModify = useSelector((state: RootState) => state.rooms.roomToModify)
  const updateMeetingDrawerOpen = useSelector(
    (state: RootState) => state.calendar.updateMeetingDrawerOpen,
  )
  const roomToModifyConfirm = useSelector(
    (state: RootState) => state.rooms.roomToModifyConfirm,
  )
  const updateRoomStatus = useSelector((state: RootState) => state.rooms.updateRoomStatus)
  const deleteMeetingStatus = useSelector(
    (state: RootState) => state.rooms.deleteRoomStatus,
  )
  const deleteGeneratedRoomStatus = useSelector(
    (state: RootState) => state.rooms.deleteGeneratedRoomStatus,
  )
  const deletePeriodicRoomStatus = useSelector(
    (state: RootState) => state.rooms.deletePeriodicRoomStatus,
  )
  const updatePlanRoomStatus = useSelector(
    (state: RootState) => state.rooms.updatePlanRoomStatus,
  )
  const fetchPeriodicRoomStatus = useSelector(
    (state: RootState) => state.rooms.fetchPeriodicRoomStatus,
  )
  const updatePeriodicRoomStatus = useSelector(
    (state: RootState) => state.rooms.updatePeriodicRoomStatus,
  )
  const periodicRoomFetch = useSelector(
    (state: RootState) => state.rooms.periodicRoomFetched,
  )
  const MAX_DATES_ARRAY = 10

  const disabled =
    !!roomToModify &&
    (roomToModify.archived ||
      updateRoomStatus === 'loading' ||
      updatePlanRoomStatus === 'loading' ||
      !hasRoomAdminRights(roomToModify))

  const [form] = Form.useForm()

  const [globalEditPeriodic, setGlobalEditPeriodic] = useState<boolean>(false)
  const [personalizePeriodicity, setPersonalizePeriodicity] = useState<
    Periodicity | undefined
  >(undefined)

  useEffect(() => {
    if (form.getFieldValue('duration') == 1440) {
      setChecked(true)
    }
  }, [])

  const datesTreeChildren = (
    isRoomToModifyPending(roomToModify) ? roomToModify?.plannedDates : []
  ).map((timestamp: number) => {
    return {
      title: () => {
        return (
          <div className="tree-dates d-flex d-flex-center">
            <span>{dayjs(timestamp).format('LLL')}</span>
            <span
              className="d-flex d-flex-middle"
              onClick={() => dispatch(deleteDateRoomToModify(timestamp))}
            >
              <AiOutlineDelete />
            </span>
          </div>
        )
      },
      key: timestamp,
    }
  })

  const { tree: datesTreeOptions } = useDatesTreeOptions(datesTreeChildren)

  let datePickerLocale = getDatePickerLocale(i18n.language)

  const [datePickerValue, setDatePickerValue] = useState<any>(dayjs(room.startDate))
  const [showTimeZone, setShowTimezone] = useState(false)
  const jwt = useSelector((state: RootState) => state.auth.jwt) || []

  useAttemptsListener([
    [
      updateRoomStatus,
      {
        success: () => {
          if (updateMeetingDrawerOpen) {
            ToastOpen({
              message: t('Meeting was successfully updated.'),
              type: 'success',
            })
            close()
          }
        },
        FORBIDDEN: () => {
          ToastOpen({
            message: t('You do not have the necessary rights.'),
            type: 'error',
          })
        },
        error: () =>
          ToastOpen({
            message: t('Error updating meeting.'),
            type: 'error',
          }),
      },
      () => dispatch(idleUpdateRoomStatus()),
    ],
    [
      updatePlanRoomStatus,
      {
        success: () => {
          ToastOpen({
            message: t('Meeting was successfully updated.'),
            type: 'success',
          })
          close()
        },
        error: () =>
          ToastOpen({
            message: t('Error updating meeting.'),
            type: 'error',
          }),
      },
      () => dispatch(idleUpdatePlanRoomStatus()),
    ],
    [
      deleteMeetingStatus,
      {
        success: () => {
          close()
        },
      },
      () => dispatch(idleDeleteRoomStatus()),
    ],
    [
      deleteGeneratedRoomStatus,
      {
        success: () => {
          close()
        },
      },
      () => dispatch(idleDeleteGeneratedRoomStatus()),
    ],
    [
      deletePeriodicRoomStatus,
      {
        success: () => {
          close()
        },
      },
      () => dispatch(idleDeletePeriodicRoomStatus()),
    ],
    [
      fetchPeriodicRoomStatus,
      {
        success: () => {
          dispatch(setRoomToModify(periodicRoomFetch))

          const rrule = RRule.fromString(periodicRoomFetch?.periodicity || '')

          const startDate = rrule.options.dtstart
          const UTCStartDate = dayjs(
            dayjs(startDate)
              .utcOffset(2 * dayjs().utcOffset(), true)
              .toDate(),
          ) // to appear without offset

          setDatePickerValue(UTCStartDate)

          form.setFieldsValue({
            name: periodicRoomFetch?.name,
            color:
              periodicRoomFetch?.creator == email
                ? periodicRoomFetch?.color
                  ? periodicRoomFetch?.color
                  : meetingColor[0].value
                : meetingColor[0].value,
            type: periodicRoomFetch?.type,
            reference: periodicRoomFetch?.reference,
            creator: periodicRoomFetch?.creator,
            duration: periodicRoomFetch?.duration,
            creatorNote: periodicRoomFetch?.creatorNote,
            startDate: UTCStartDate,
            creationDate: dayjs(periodicRoomFetch?.createdAt),
            participants: periodicRoomFetch?.participants,
          })

          setPersonalizePeriodicity({
            dtstart: rrule.options.dtstart,
            interval: rrule.options.interval,
            freq:
              defaultFrequency.find((item) => item.value == rrule.options.freq)?.label ||
              'day',
            byweekday: defaultByWeekDay
              .filter((day) => {
                if (day.value.weekday != undefined) {
                  return (rrule.options.byweekday || []).includes(day.value.weekday)
                }
              })
              .map((day) => day.label),
            until: rrule.options.until || undefined,
            count: rrule.options.count || undefined,
            bysetpos: rrule.options.bysetpos,
            bymonth: rrule.options.bymonth,
            bymonthday: rrule.options.bymonthday,
          })
        },
        error: () =>
          ToastOpen({
            message: t('Error retrieving meeting information.'),
            type: 'error',
          }),
      },
      () => dispatch(idleFetchPeriodicRoomStatus()),
    ],
    [
      updatePeriodicRoomStatus,
      {
        success: () => {
          ToastOpen({
            message: t('Meetings were successfully updated.'),
            type: 'success',
          })
          close()
        },
        error: () =>
          ToastOpen({
            message: t('Error updating meetings.'),
            type: 'error',
          }),
      },
      () => dispatch(idleupdatePeriodicRoomStatus()),
    ],
  ])

  function onConfirmOk() {
    if (roomToModifyConfirm) {
      if (isRoomToModifyPending(roomToModify)) {
        dispatch(
          updatePlanRoom({
            room: roomToModifyConfirm as RoomToModifyPending,
            availableDates: roomToModify?.plannedDates || [],
          }),
        )
      } else if (globalEditPeriodic) {
        dispatch(updatePeriodicRoom(roomToModifyConfirm))
      } else {
        dispatch(updateRoom(roomToModifyConfirm))
      }
      dispatch(setRoomToModifyConfirm(undefined))
    }
  }

  function onConfirmCancel() {
    dispatch(setRoomToModifyConfirm(undefined))
  }

  const addFile = (file: RoomFile) => {
    dispatch(
      addUnconfirmedFile({
        roomId: roomToModify?.id || '',
        file: file,
      }),
    )
  }

  const removeFile = (fileUid: string) => {
    dispatch(
      removeUnconfirmedFile({
        roomId: room?.id || '',
        fileUid: fileUid,
      }),
    )
  }

  const onCheckboxChange = () => {
    const defaultDuration = isChecked ? 30 : 1440
    const tempDate = form.getFieldValue('startDate') || dayjs()

    form.setFieldValue('duration', defaultDuration)
    form.setFieldValue('startDate', tempDate.set({ hour: 0, minute: 0, second: 0 }))
    setChecked(!isChecked)
  }

  function onMeetingModify(values: FormValues) {
    const newValues: RoomToModify = {
      id: roomToModify?.id || '',
      creator: roomToModify?.creator,
      name: values['name'],
      color: values['color']?.toHexString?.() || room.color,
      type: values['type'] || roomToModify?.type,
      reference: values['reference'] || [],
      duration: values['duration'],
      creatorNote: values['creatorNote'] ?? roomToModify?.creatorNote,
      participants: roomToModify?.participants || [],
      invited: roomToModify?.invited || [],
      unconfirmedFiles: roomToModify?.unconfirmedFiles || [],
      unconfirmedFileDeletions: roomToModify?.unconfirmedFileDeletions || [],
      startDate: values['startDate']?.toISOString(),
      createFromPending: roomToModify?.createFromPending || false,
      periodicRoomId: roomToModify?.periodicRoomId,
      periodicity: personalizePeriodicity
        ? new RRule({
            dtstart: values['startDate'].utc(true).toDate(),
            interval: personalizePeriodicity?.interval,
            freq: defaultFrequency.find(
              (item) => item.label == personalizePeriodicity?.freq,
            )?.value,
            byweekday: defaultByWeekDay
              .filter((item) => personalizePeriodicity?.byweekday?.includes(item.label))
              .map((item) => item.value),
            until: personalizePeriodicity?.until
              ? dayjs(personalizePeriodicity?.until).toDate()
              : undefined,
            count: personalizePeriodicity?.count,
            bymonth: personalizePeriodicity.bymonth,
            bymonthday: personalizePeriodicity.bymonthday,
            bysetpos: personalizePeriodicity?.bysetpos,
          }).toString()
        : undefined,
    }
    if (isRoomToModifyPending(roomToModify)) {
      dispatch(
        setRoomToModify({ ...newValues, plannedDates: roomToModify?.plannedDates || [] }),
      )
    } else {
      dispatch(setRoomToModify(newValues))
    }

    if (
      (roomToModify?.participants?.length || 0) === 0 &&
      (roomToModify?.invited?.length || 0) === 0
    ) {
      if (isRoomToModifyPending(roomToModify)) {
        dispatch(
          setRoomToModifyConfirm({
            ...newValues,
            plannedDates: roomToModify?.plannedDates || [],
          }),
        )
      } else {
        dispatch(setRoomToModifyConfirm(newValues))
      }
    } else {
      if (isRoomToModifyPending(roomToModify)) {
        dispatch(
          updatePlanRoom({
            room: newValues as RoomToModifyPending,
            availableDates: roomToModify?.plannedDates || [],
          }),
        )
      } else if (globalEditPeriodic) {
        dispatch(updatePeriodicRoom(newValues))
      } else {
        dispatch(
          updateRoom({ ...newValues, startDate: values['startDate'].toISOString() }),
        )
      }
    }
    dispatch(setUpdateMeetingDrawerOpen(true))
  }

  useEffect(() => {
    dispatch(setRoomToModify(room))

    return () => {
      dispatch(setRoomToModify(undefined))
    }
  }, [])

  const onAddDate = (startDate: Dayjs) => {
    if (!isRoomToModifyPending(roomToModify)) return
    if (
      roomToModify?.plannedDates?.find(
        (d) => d === new Date(startDate.toISOString()).getTime(),
      )
    ) {
      ToastOpen({
        message: t('This date is already selected.'),
        type: 'info',
      })
    } else if (
      roomToModify?.plannedDates &&
      roomToModify?.plannedDates?.length > MAX_DATES_ARRAY - 1
    ) {
      ToastOpen({
        message: t('You cannot add more dates.'),
        type: 'error',
      })
    } else {
      dispatch(addDateRoomToModify(startDate.toISOString()))
    }
  }

  return (
    <>
      <div className="new-meeting">
        {showTitle && (
          <div className="layout-header">
            <h1>
              {t('Manage meeting')} {unescape(room?.name || '')}
            </h1>
          </div>
        )}
        <Form
          form={form}
          onFinish={onMeetingModify}
          initialValues={{
            name: unescape(room.name || ''),
            color:
              room.creator?.email === email
                ? room.color
                  ? room.color
                  : meetingColor[0].value
                : room.participants
                  ? room.participants.find((participant) => participant.email === email)
                      ?.color
                  : room.color,
            type: room.type,
            reference: room.reference,
            creator: room.creator,
            duration: room.duration,
            creatorNote: room.creatorNote,
            startDate: room.startDate ? dayjs(room.startDate) : null,
            creationDate: dayjs(room.createdAt),
            participants: room.participants,
          }}
          name="general"
          autoComplete="off"
          layout="vertical"
          scrollToFirstError={true}
        >
          <Row className="form-meeting-container">
            <Col span={24}>
              {!globalEditPeriodic &&
                room.periodicRoomId &&
                room.creator?.email == email && (
                  <Alert
                    message={
                      <Space className="inline-alert">
                        <Trans ns="meetings" i18nKey="MEETING_EDIT_FULL_SET">
                          You are modifying a single occurrence of a repeated series, to
                          modify the series,
                          <Button
                            type="text"
                            onClick={() => {
                              setGlobalEditPeriodic(true)
                              if (room.periodicRoomId) {
                                dispatch(fetchPeriodicRoom({ pid: room.periodicRoomId }))
                              }
                            }}
                          >
                            click here
                          </Button>
                          .
                        </Trans>
                      </Space>
                    }
                    type="info"
                  />
                )}
              {globalEditPeriodic && (
                <Alert message={t('You are editing the full set.')} type="warning" />
              )}
            </Col>
          </Row>
          <Row>
            <div className="layout-header-bottom">
              <h4 className="layout-list-header">{t('General')}</h4>
            </div>
          </Row>
          <Divider />
          <Row gutter={[24, 8]} className="form-meeting-container">
            <Col span={20}>
              <Form.Item
                style={{ width: '100%' }}
                label={t('Title')}
                name="name"
                rules={[{ required: true, message: t('Please input title') }]}
              >
                <Input disabled={disabled} />
              </Form.Item>
            </Col>
            <Col sm={4}>
              <div className="d-flex d-flex-middle">
                <span className="mb-02em mr-05rem text-color pointer-events-none">
                  {t('Color', { ns: 'common' })}
                </span>
                <Tooltip
                  title={t('Customize your own planning')}
                  className="cursor-pointer"
                >
                  <BiInfoCircle size="1em" className="info-color" />
                </Tooltip>
              </div>
              <Form.Item name="color">
                <ColorPicker
                  disabled={disabled}
                  className="new-form-color-picker"
                  panelRender={(_, { components: { Presets } }) => <Presets />}
                  trigger="hover"
                  value={meetingColor.length > 0 ? meetingColor[0].value : ''}
                  presets={[
                    {
                      label: '',
                      colors: meetingColor.map((col) => col.value),
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={!disabled ? 12 : 24}>
              <Form.Item
                label={t('Type of meeting')}
                name="type"
                initialValue={room.type}
              >
                <Select
                  placeholder={t('Type of meeting')}
                  defaultOpen={false}
                  value={room.type}
                  disabled={disabled}
                >
                  <Select.Option value={RoomType.Videoconferencing}>
                    {t('Videoconferencing')}
                  </Select.Option>
                  <Select.Option value={RoomType.Phone}>{t('Phone')}</Select.Option>
                  <Select.Option value={RoomType.InPerson}>
                    {t('In-Person')}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            {!disabled && <TagReference initialRef={room.reference} />}
            <Col span={24} sm={12}>
              <Form.Item
                label={t('Meeting at')}
                className="meeting-start-date-form-item mb-0"
                name="startDate"
                rules={[
                  {
                    required: isRoomToModifyPending(room) ? false : true,
                    message: t('Please input start date'),
                  },
                ]}
              >
                <DatePicker
                  className="w-100"
                  showTime={
                    !isChecked && {
                      defaultValue:
                        isRoomToModifyPending(room) || isRoomModifyPeriodic(room)
                          ? dayjs('00:00:00', 'HH:mm:ss')
                          : dayjs(room.startDate, 'HH:mm:ss'),
                      format: 'HH:mm',
                      minuteStep: 5,
                    }
                  }
                  showSecond={false}
                  locale={datePickerLocale}
                  format="DD/MM/YYYY HH:mm"
                  inputReadOnly
                  disabledDate={(current) =>
                    current.isBefore(dayjs().subtract(1, 'day').toString())
                  }
                  onOk={(value: Dayjs) => onAddDate(value)}
                  onChange={setDatePickerValue}
                  disabled={disabled}
                />
              </Form.Item>
            </Col>
            <Col span={24} sm={12}>
              <Form.Item
                label={t('Duration')}
                name="duration"
                rules={[{ required: true, message: t('Please input duration') }]}
              >
                <InputNumber
                  min={1}
                  max={1440}
                  addonAfter="min"
                  disabled={isChecked || disabled}
                />
              </Form.Item>
              <Checkbox
                className="mt-05rem"
                checked={isChecked}
                onChange={onCheckboxChange}
                disabled={disabled}
              >
                {t('All day long')}
              </Checkbox>
            </Col>
            <Col span={24}>
              <div
                className="show-timezone-txt"
                hidden={!datePickerValue}
                onClick={() => setShowTimezone(!showTimeZone)}
              >
                <span className="cursor-pointer d-flex d-flex-middle">
                  {t('See time for another timezone')}
                  {showTimeZone ? <BsChevronUp /> : <BsChevronDown />}
                </span>
              </div>
              <div hidden={!showTimeZone}>
                <TimeZoneVisualizer date={datePickerValue} />
              </div>
            </Col>
            {(globalEditPeriodic || !room.periodicRoomId) && (
              <Col span={24} sm={12}>
                <PeriodicityForm
                  personalizePeriodicity={personalizePeriodicity}
                  setPersonalizePeriodicity={setPersonalizePeriodicity}
                  hidden={!globalEditPeriodic && !!room.periodicRoomId}
                  defaultDate={datePickerValue}
                />
              </Col>
            )}
            <Col span={24} sm={12}>
              <Form.Item label={t('Creation date')} name="creationDate">
                <DatePicker
                  className="w-100"
                  showSecond={false}
                  locale={datePickerLocale}
                  format="DD/MM/YYYY HH:mm"
                  inputReadOnly
                  disabled={true}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t('Note from the organizer')}
                name="creatorNote"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <TextAreaEditor value={room.creatorNote} disabled={disabled} />
              </Form.Item>
            </Col>
          </Row>

          <Row
            gutter={[24, 8]}
            className="form-meeting-container mt-05rem"
            align={'bottom'}
          >
            <Col span={24}></Col>
            {!roomToModify?.startDate && (
              <>
                <Col span={24} sm={12}>
                  <Tree
                    showLine={true}
                    switcherIcon={false}
                    defaultExpandedKeys={['parentTree']}
                    selectable={false}
                    treeData={datesTreeOptions}
                  />
                </Col>
                <Col span={24} sm={12}>
                  {/* Planner options (unique choice, reminder...) */}
                </Col>
              </>
            )}
          </Row>
        </Form>

        <Row>
          <div
            className={
              disabled ? 'layout-header-bottom' : 'layout-header-bottom mt-1_5rem'
            }
          >
            <h4 className="layout-list-header">{t('Participants')}</h4>
          </div>
        </Row>
        <Divider />
        <ManageParticipants
          room={room}
          vertical={false}
          disabled={disabled}
          addParticipant={addParticipant}
          addInvited={addInvited}
          deleteParticipant={deleteParticipant}
          deleteInvited={deleteInvited}
          updateParticipantRole={updateParticipantRole}
          updateInvitedRole={updateInvitedRole}
        />
        <Row>
          <div
            className={disabled ? 'layout-header-bottom' : 'layout-header-bottom mt-1rem'}
          >
            <h4 className="layout-list-header">{t('Documents', { ns: 'common' })}</h4>
          </div>
        </Row>
        <Divider />
        <Row className="form-meeting-container align-with-others" gutter={[8, 8]}>
          <Col span={24} hidden={disabled}>
            <DocumentsUpload
              room={roomToModify}
              title={t('Upload files for this meeting', { ns: 'common' })}
              immediateSave={false}
              disabled={disabled}
              newRoomAddUnconfirmedFile={addFile}
            />
          </Col>
          <Col span={24}>
            <h4 className={disabled ? 'uppercase' : 'uppercase mt-2rem'}>
              {t('Available files', { ns: 'common' })}
            </h4>
          </Col>
          <Col span={24}>
            <DocumentsList
              disabled={disabled}
              room={roomToModify}
              immediateSave={false}
              newRoomRemoveUnconfirmedFile={removeFile}
            />
          </Col>
        </Row>

        <Row
          className={`form-meeting-container sticky-create-meeting align-with-others ${
            fromCalendar ? 'sticky-create-meeting--calendar' : ''
          }`}
        >
          <Col span={24} className="submit-button">
            {!room.archived && (
              <Button
                className="mr-1rem"
                type="primary"
                danger
                onClick={() =>
                  dispatch(
                    setRoomToDelete({
                      room,
                      pending: roomToModify?.startDate ? false : true,
                    }),
                  )
                }
              >
                {room.creator?.email === email
                  ? t('Cancel meeting')
                  : t('Leave the meeting')}
              </Button>
            )}
            <Button type="default" className="mr-1rem" onClick={close}>
              {t('Back', { ns: 'common' })}
            </Button>
            {!disabled && (
              <Button
                type="primary"
                onClick={() => {
                  form.submit()
                }}
              >
                {t('Update meeting')}
              </Button>
            )}
          </Col>
        </Row>
      </div>

      <Modal
        centered
        className="line-1"
        open={roomToModifyConfirm !== undefined}
        title={
          <>
            {t('Manage meeting')} <strong>{unescape(roomToModify?.name || '')}</strong>
          </>
        }
        onOk={onConfirmOk}
        onCancel={onConfirmCancel}
        okText={t('Yes')}
        cancelText={t('Back')}
      >
        <p>{t('There are no participants in the meeting, continue?')}</p>
      </Modal>
    </>
  )
}

export default RoomManage
