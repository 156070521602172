import { Button, Modal } from 'antd'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { AppDispatch, RootState } from '../store'
import { useDispatch, useSelector } from 'react-redux'
import { setShowModalConnectionError } from '../features/room/redux/meSlice'
import { ENV_CONSTANTS } from '../utils/Utils'

function CallConnectionErrorModal() {
  const { t } = useTranslation('room')
  const showModalConnectionError = useSelector(
    (state: RootState) => state.me.showModalConnectionError,
  )
  const dispatch = useDispatch<AppDispatch>()

  return (
    <Modal
      centered
      title={t('Connection error', { ns: 'common' })}
      open={showModalConnectionError}
      confirmLoading={false}
      footer={
        <div className="d-flex">
          <Button
            onClick={() => dispatch(setShowModalConnectionError(false))}
            className="mr-05rem"
          >
            {t('Close', { ns: 'common' })}
          </Button>
          <a href="/faq/network" target="_blank">
            <Button type="primary">{t('See', { ns: 'common' })}</Button>
          </a>
        </div>
      }
    >
      <p className="text-justify unwrapped-text">
        <Trans
          i18nKey="UNABLE_CONNECT_CALL"
          shouldUnescape={true}
          ns="room"
          values={{ applicationName: ENV_CONSTANTS['DEFAULT_APPLICATION_NAME'] }}
        />
        <br />
        {t(
          'Please ensure that your device has an active Internet connection and that all device and network security solutions have been configured to allow access to our servers.',
        )}
        <br />
        {t('For further information, please see the ', { ns: 'faq' })}
        <a href="/faq/network" target="_blank">
          {t('network configuration page', { ns: 'faq' })}
        </a>
        .
      </p>
    </Modal>
  )
}

export default CallConnectionErrorModal
