import React from 'react'
import { Tooltip } from 'antd'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { RootState } from '../../store'
import classNames from 'classnames'
import { useIsMobile } from '../../utils/hooks/useIsMobile'
import { BsFillExclamationCircleFill } from 'react-icons/bs'

interface Props {
  tooltipVisible: boolean
  participantsDrawerOpen: boolean
  toggleDrawerParticipants: () => void
}

function CallControlsParticipants({
  tooltipVisible,
  participantsDrawerOpen,
  toggleDrawerParticipants,
}: Props) {
  const room = useSelector((state: RootState) => state.room)
  const hideOnMouseStop = useSelector((state: RootState) => state.room.hideOnMouseStop)
  const { t } = useTranslation('room')
  const isMobile = useIsMobile()

  return room.waitingInvited.length > 0 ? (
    <Tooltip
      title={<strong>{t('Waiting participants')}</strong>}
      open={tooltipVisible && room.waitingInvited.length > 0 && !hideOnMouseStop}
      color="#e12727"
    >
      <div
        className={classNames({
          'call-control': !isMobile,
          'call-control-dropdown': isMobile,
          'call-control--active': participantsDrawerOpen,
        })}
        onClick={toggleDrawerParticipants}
      >
        {isMobile ? (
          <img className="users" src="/images/users_blue.svg" alt="users" />
        ) : (
          <img className="users" src="/images/users.svg" alt="users" />
        )}
        {room.waitingInvited.length > 0 && (
          <BsFillExclamationCircleFill
            fill="#f50"
            className="call-control-attention"
            strokeWidth={1}
          />
        )}
      </div>
    </Tooltip>
  ) : (
    <Tooltip title={t('Participants')}>
      <div
        className={classNames({
          'call-control': !isMobile,
          'call-control-dropdown': isMobile,
          'call-control--active': participantsDrawerOpen,
        })}
        onClick={toggleDrawerParticipants}
      >
        {isMobile ? (
          <img className="users" src="/images/users_blue.svg" alt="users" />
        ) : (
          <img className="users" src="/images/users.svg" alt="users" />
        )}
        {room.waitingInvited.length > 0 && (
          <BsFillExclamationCircleFill
            fill="#f50"
            className="call-control-attention"
            strokeWidth={1}
          />
        )}
      </div>
    </Tooltip>
  )
}

export default CallControlsParticipants
