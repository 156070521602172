import { createSelector } from '@reduxjs/toolkit'
import { Collapse, Input, Space } from 'antd'
import classNames from 'classnames'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getChatDetails } from '../../../features/room/RoomChat/ChatLibrary'
import { AppDispatch, RootState } from '../../../store'
import CreateChat from '../CreateChat/CreateChat'
import '../CreateChat/ModifyChatPeers.scss'
import { Squad } from '../Squad/squadListSlice'
import './ChatList.scss'
import { Chat as ChatInterface, setCurrentChatId } from './chatsSlice'
import DraftMessage from './DraftMessage'
import LastMessage from './LastMessage'
import { normalizeString } from './chatService'
import ShareDriveToTeamModal from './ShareDriveToTeam/ShareDriveToTeamModal'

interface Props {
  inCall?: boolean
  currentSquadId: string
  setOpenResponsiveDrawerMenu?: (open: boolean) => void
}

export default function ChatList({
  inCall,
  currentSquadId,
  setOpenResponsiveDrawerMenu,
}: Props) {
  const selectSquad = (state: RootState) => state.chatSquads.squads[currentSquadId]
  const selectChats = (state: RootState) => state.chats.chats[currentSquadId]
  const selectChatsDetails = (state: RootState) => state.chats.chatsDetails
  const { t } = useTranslation('chat')
  const [q, setQ] = useState('')
  const [filterType, setFilterType] = useState<'all' | 'private' | 'channel'>('all')
  const qNormalized = normalizeString(q)

  const chatsSelector = createSelector(
    [selectSquad, selectChats, selectChatsDetails, (_, qNormalized) => qNormalized],
    (squad, chats, chatsDetails, qNormalized) => {
      if (!squad || !chats) {
        return { displayedChats: [], archivedChats: [] }
      }

      const lastMessageChatSort = (a: ChatInterface, b: ChatInterface) => {
        const aDate = chatsDetails[a.id]?.lastMessage?.createdAt
          ? dayjs(chatsDetails[a.id]?.lastMessage?.createdAt)
          : dayjs('1970-01-01')
        const bDate = chatsDetails[b.id]?.lastMessage?.createdAt
          ? dayjs(chatsDetails[b.id]?.lastMessage?.createdAt)
          : dayjs('1970-01-01')

        return bDate.isAfter(aDate) ? 1 : -1
      }

      const chatSorted = [...chats].sort(lastMessageChatSort)

      let filteredChats = chatSorted.filter((c) => {
        const { name } = getChatDetails(c)
        if (qNormalized) {
          const nameNormalized = normalizeString(name)
          return nameNormalized.includes(qNormalized)
        }
        return true
      })

      const archivedChats = filteredChats.filter((c) => c.archived)
      let displayedChats = filteredChats.filter((c) => !c.archived)

      if (filterType === 'private') {
        displayedChats = displayedChats.filter((c) => c.peerToPeer)
      } else if (filterType === 'channel') {
        displayedChats = displayedChats.filter((c) => !c.peerToPeer)
      }

      return { displayedChats, archivedChats }
    },
  )

  const { displayedChats, archivedChats } = useSelector((state: RootState) =>
    chatsSelector(state, qNormalized),
  )

  return (
    <>
      <CreateChat squadId={currentSquadId} inCall={inCall} />

      <ShareDriveToTeamModal squadId={currentSquadId} />

      <div className="mh-1em pb-1rem">
        <div className="filter-container">
          <div
            className={classNames('filter-button', { active: filterType === 'all' })}
            onClick={() => setFilterType('all')}
          >
            {t('All')}
          </div>
          <div
            className={classNames('filter-button', { active: filterType === 'private' })}
            onClick={() => setFilterType('private')}
          >
            {t('Privates')}
          </div>
          <div
            className={classNames('filter-button', { active: filterType === 'channel' })}
            onClick={() => setFilterType('channel')}
          >
            {t('Channels')}
          </div>
        </div>
      </div>

      <Space.Compact className="mh-1em pb-1rem">
        <Input
          placeholder={t('Search')}
          allowClear
          onChange={(v) => setQ(v.target.value)}
        />
      </Space.Compact>

      <Scrollbars className="ChatListComponent" autoHide>
        <div>
          {displayedChats.map((c) => {
            const { name, icon, backgroundColor } = getChatDetails(c)
            return (
              <ChatListLine
                key={c.id}
                squadId={currentSquadId}
                chat={c}
                setOpenResponsiveDrawerMenu={setOpenResponsiveDrawerMenu}
                name={name}
                icon={icon}
                backgroundColor={backgroundColor}
                inCall={inCall}
              />
            )
          })}
          {archivedChats.length > 0 && (
            <Collapse
              className="chat_group"
              collapsible="header"
              defaultActiveKey={[]}
              ghost
              items={[
                {
                  key: '1',
                  label: (
                    <div className="chat_group_title">
                      <div className="mr-05rem">{t('Archives')}</div>
                    </div>
                  ),
                  children: archivedChats.map((c) => {
                    const { name, icon, backgroundColor } = getChatDetails(c)
                    return (
                      <ChatListLine
                        key={c.id}
                        squadId={currentSquadId}
                        chat={c}
                        setOpenResponsiveDrawerMenu={setOpenResponsiveDrawerMenu}
                        name={name}
                        icon={icon}
                        backgroundColor={backgroundColor}
                        inCall={inCall}
                      />
                    )
                  }),
                },
              ]}
            />
          )}
        </div>
      </Scrollbars>
    </>
  )
}

interface ChatLineProps {
  chat: ChatInterface
  squadId: string
  setOpenResponsiveDrawerMenu?: (open: boolean) => void
  name: string
  icon: string
  backgroundColor: string
  inCall?: boolean
}

function ChatListLine({
  squadId,
  chat,
  setOpenResponsiveDrawerMenu,
  name,
  icon,
  backgroundColor,
  inCall,
}: ChatLineProps) {
  const { t } = useTranslation('chat')
  const dispatch = useDispatch<AppDispatch>()
  const chatsDetails = useSelector((state: RootState) => state.chats.chatsDetails)
  const squads = useSelector((state: RootState) => state.chatSquads.squads)
  const squad = squads[squadId]

  const lastMessage = chatsDetails[chat.id]?.lastMessage
  const unreadMessagesCount = chatsDetails[chat.id]?.unreadMessagesCount
  const currentChatId = useSelector((state: RootState) => state.chats.currentChat?.id)
  const peersStatuses = useSelector(
    (state: RootState) => state.chatSquads.peersStatuses[squad.id],
  )
  const typingUsers = useSelector((state: RootState) => state.chats.typingUsers[chat.id])
  const messageInputDraft = useSelector(
    (state: RootState) => state.chats.messageInputDraft[chat.id],
  )

  function selectChat(chatId: string) {
    dispatch(setCurrentChatId(chatId))
    if (setOpenResponsiveDrawerMenu) {
      setOpenResponsiveDrawerMenu(false)
    }
  }

  return (
    <div
      key={chat.id}
      onClick={() => selectChat(chat.id)}
      className={classNames({
        chat_item: true,
        'chat_item--current': chat.id === currentChatId,
        'chat_item--unread': unreadMessagesCount > 0,
        'chat_item--hide': !lastMessage && chat.peerToPeer && !inCall,
      })}
    >
      <div className="chat_item_avatar_container">
        <div className="chat_item_avatar" style={{ backgroundColor }}>
          {icon}
        </div>
        {chat.peerToPeer &&
          chat.peer &&
          peersStatuses &&
          peersStatuses[chat.peer.email] === 'ONLINE' && (
            <div className="chat_item_p2p_online" />
          )}
      </div>
      <div className="chat_item_details">
        <div className="chat_item_details_first">
          <div className="chat_item_title">
            <span>
              <span className="chat_item_name">{t(name, { ns: 'meetings' })}</span>
            </span>
          </div>
          <div className="chat_item_last_message_date">
            {lastMessage &&
              (dayjs().format('LL') === dayjs(lastMessage.createdAt).format('LL')
                ? dayjs(lastMessage.createdAt).format('HH:mm')
                : dayjs(lastMessage.createdAt).format('DD/MM'))}
          </div>
        </div>
        <div className="chat_item_details_second">
          {typingUsers && Object.values(typingUsers).length > 0 ? (
            <>
              <div className="chat_item_last_message">
                {Object.values(typingUsers).length == 1
                  ? t('USER_TYPING', {
                      user: Object.values(typingUsers)
                        .map((user) => user.firstName)
                        .join(', '),
                    })
                  : t('Several people are writing...')}
              </div>
              {unreadMessagesCount > 0 && (
                <div className="chat_item_unread_messages_count">
                  {unreadMessagesCount}
                </div>
              )}
            </>
          ) : messageInputDraft?.text || messageInputDraft?.files ? (
            <DraftMessage messageInputDraft={messageInputDraft} />
          ) : (
            <LastMessage
              lastMessage={lastMessage}
              unreadMessagesCount={unreadMessagesCount}
            />
          )}
        </div>
      </div>
    </div>
  )
}
