import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { useEffect } from 'react'

export function useShareDriveToTeamHandler(setText: (text: string) => void) {
  const shareToTeamUrl = useSelector((state: RootState) => state.drive.shareToTeamUrl)
  useEffect(() => {
    if (shareToTeamUrl) {
      setText(shareToTeamUrl)
    }
  }, [shareToTeamUrl])
}
