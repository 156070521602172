import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { useDriveLoader } from './UseDriveLoader'
import { fetchDriveRights } from '../../features/Drives/redux/drivesSlice'

export function useFetchDriveRights() {
  const dispatch = useDispatch<AppDispatch>()
  const auth = useSelector((state: RootState) => state.auth)
  const { creatorEmail, selectedDriveFolder } = useDriveLoader()

  useEffect(() => {
    if (selectedDriveFolder?.driveId && auth.email != creatorEmail) {
      dispatch(
        fetchDriveRights({
          driveId: selectedDriveFolder.driveId as string,
        }),
      )
    }
  }, [selectedDriveFolder?.driveId])
}
