import React, { useState } from 'react'
import { Button, Col, Form, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import {
  RoomInterface,
  RoomParticipant,
  RoomParticipantRoleEnum,
} from '../../../features/room/RoomInterface'
import './AddParticipantForm.scss'
import { IoIosAdd } from 'react-icons/io'
import '../../../index.scss'
import { meetingColor } from '../RoomManage'
import { Option } from '../../DrivesComponent/CreateDrive/DriveFormAddParticipant'
import ParticipantSearch from './ParticipantSearch'
import RoleSelector from './RoleSelector'
import { useAddParticipant } from '../../../utils/hooks/useAddParticipant'

export interface KnownParticipants {
  [email: string]: { firstName: string; lastName: string }
}

interface AddParticipantFormProps {
  room?: RoomInterface
  vertical: boolean
  addParticipant: (payload: RoomParticipant) => any
  addInvited: (values: any) => any
}

function AddParticipantForm({
  room,
  vertical,
  addParticipant,
  addInvited,
}: AddParticipantFormProps) {
  const { t } = useTranslation('meetings')
  const [addParticipantForm] = Form.useForm()
  const [knownPeers, dispatchKnownPeers] = useState<KnownParticipants>({})
  const [options, setOptions] = useState<Option[]>([])
  const { onAddParticipant } = useAddParticipant({
    room,
    addParticipant,
    addInvited,
    options,
    setOptions,
    form: addParticipantForm,
    knownPeers,
  })

  const submitFormElement = (
    <Form.Item>
      <Button
        className={vertical ? 'full-width-btn' : ''}
        type="primary"
        size="small"
        icon={<IoIosAdd size="1.5rem" />}
        htmlType="submit"
      >
        <span className="hideOnMobile">{t('Add', { ns: 'common' })}</span>
      </Button>
    </Form.Item>
  )

  return (
    <Form
      form={addParticipantForm}
      initialValues={{
        role: RoomParticipantRoleEnum.Attendee,
        color: meetingColor[0].value,
      }}
      name="basic"
      onFinish={onAddParticipant}
      layout="vertical"
      autoComplete="off"
      disabled={room?.archived}
      scrollToFirstError={true}
    >
      {vertical ? (
        <>
          <ParticipantSearch
            room={room}
            form={addParticipantForm}
            options={options}
            setOptions={setOptions}
            knownPeers={knownPeers}
            dispatchKnownPeers={dispatchKnownPeers}
          />
          <div className="mt-1rem">
            <RoleSelector />
          </div>
          <div className="mt-1rem">{submitFormElement}</div>
        </>
      ) : (
        <Row className="search-row addParticipant">
          <Col span={24} className="label">
            <i>{t('Search and choose participants to invite')}</i>
          </Col>
          <Col span={10} className="input-group">
            <ParticipantSearch
              room={room}
              form={addParticipantForm}
              options={options}
              setOptions={setOptions}
              knownPeers={knownPeers}
              dispatchKnownPeers={dispatchKnownPeers}
            />
          </Col>
          <Col span={10} className="role-selector">
            <RoleSelector />
          </Col>
          <Col span={4} className="action-button">
            {submitFormElement}
          </Col>
        </Row>
      )}
    </Form>
  )
}

export default AddParticipantForm
