import dayjs from 'dayjs'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import './ChatMessages.scss'
import { Chat, ChatMessage } from './chatsSlice'
import { TiMessages } from 'react-icons/ti'
import Message from './Message'
import EmojiPickerModal from './EmojiPickerModal'

interface Props {
  chat: Chat
  loading: boolean
  unreadMessagesCount: number | undefined
}

function ChatMessages({ chat, loading, unreadMessagesCount }: Props) {
  const { t } = useTranslation('chat')
  const chatMessages = useSelector(
    (state: RootState) => state.chats.chatsMessages[chat.id],
  )

  let messages: ChatMessage[] = []
  if (chatMessages) {
    messages = [...Object.values(chatMessages.chunks).flat(), ...chatMessages.live]
  }
  const [emojiPickerModal, setEmojiPickerModal] = useState<boolean>(false)
  const [messageToReact, setMessageToReact] = useState<string | undefined>(undefined)
  const [mousePositionEmoji, setMousePositionEmoji] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  })

  const openEmojiPickerModal = (e: React.MouseEvent<HTMLElement>, messageId: string) => {
    e.preventDefault()

    setMessageToReact(messageId)
    setMousePositionEmoji({ x: e.clientX, y: e.clientY })
    setEmojiPickerModal(true)
  }

  return (
    <div
      className="chat_messages"
      id="chat_messages_container" /* only for smooth scrolling to bottom */
    >
      {messages.length === 0 && !loading ? (
        <div className="empty-container">
          <TiMessages size="4em" />
          <h2 className="text-color-default">
            {t('Send a first message to this conversation')}
          </h2>
        </div>
      ) : (
        <DisplayMessages
          messages={messages}
          chat={chat}
          unreadMessagesCount={unreadMessagesCount}
          openEmojiPickerModal={openEmojiPickerModal}
        />
      )}
      <EmojiPickerModal
        open={emojiPickerModal}
        setOpen={setEmojiPickerModal}
        messageId={messageToReact}
        chatId={chat.id}
        mousePosition={mousePositionEmoji}
      />
    </div>
  )
}

function DisplayMessages({
  messages,
  chat,
  unreadMessagesCount,
  openEmojiPickerModal,
}: Omit<Props, 'loading'> & {
  messages: ChatMessage[]
  openEmojiPickerModal: (e: React.MouseEvent<HTMLElement>, messageId: string) => void
}) {
  const { t } = useTranslation('chat')
  const isGroupChat = !chat.peerToPeer
  const lastReadMessage = useRef<ChatMessage>()
  const result: React.ReactNode[] = []
  let currentDate: string | null = null
  const DELAY = 5 * 60 * 1000

  if (unreadMessagesCount && unreadMessagesCount > 0) {
    lastReadMessage.current = messages[messages.length - unreadMessagesCount]
  }

  messages.forEach((message, index) => {
    const messageDate =
      dayjs(message.createdAt).format('LL') === dayjs().format('LL')
        ? t('Today', { ns: 'calendar' })
        : dayjs(message.createdAt).format('LL')

    const shouldShowMetadata =
      index > 0
        ? messages[index - 1].peerEmail !== message.peerEmail ||
          dayjs(message.createdAt).diff(dayjs(messages[index - 1].createdAt)) > DELAY
        : true

    if (message === lastReadMessage.current) {
      result.push(
        <div key="new_messages" id="new_messages" className="chat_message_new_separator">
          <div className="new_separator_text">{t('New messages')}</div>
          <div className="new_separator_line"></div>
        </div>,
      )
    }

    if (currentDate !== messageDate) {
      currentDate = messageDate
      result.push(
        <div className="chat_message_date_separator" key={messageDate}>
          <div className="separator_line" />
          <div className="chat_message_date_separator_text">{messageDate}</div>
          <div className="separator_line" />
        </div>,
      )
    }

    result.push(
      <div
        key={message.id}
        style={{
          marginTop: shouldShowMetadata ? '1.5rem' : '0.5rem',
        }}
      >
        <Message
          message={message}
          chat={chat}
          key={message.id}
          openEmojiPickerModal={openEmojiPickerModal}
          showAvatar={isGroupChat && shouldShowMetadata}
          showName={isGroupChat && shouldShowMetadata}
        />
      </div>,
    )
  })

  //be careful of placing some extra content here, you might break scroll.
  return <>{result}</>
}
export default ChatMessages
