import React from 'react'
import { Modal } from 'antd'
import { AppDispatch, RootState } from '../../store'
import { useDispatch, useSelector } from 'react-redux'
import {
  deleteFolder,
  idleDeleteFolderStatus,
  setFolderToDelete,
} from '../../features/Drives/redux/drivesSlice'
import { Trans, useTranslation } from 'react-i18next'
import { limitText } from '../../utils/Utils'
import { useToastContext } from '../Toast/ToastContext'
import { useAttemptsListener } from 'auxasphere-react-kit'

function DeleteFolderModal() {
  const { t } = useTranslation('drives')
  const dispatch = useDispatch<AppDispatch>()
  const selectedDriveFolder = useSelector(
    (state: RootState) => state.drive.selectedDriveFolder,
  )

  const folderToDelete = useSelector((state: RootState) => state.drive.folderToDelete)

  const deleteFolderStatus = useSelector(
    (state: RootState) => state.drive.deleteFolderStatus,
  )
  const { ToastOpen } = useToastContext()

  useAttemptsListener([
    [
      deleteFolderStatus,
      {
        success: () => {
          ToastOpen({
            message: t('Folder was successfully removed.'),
            type: 'success',
          })
        },
        NOT_ENOUGH_FOLDER_RIGHT: () => {
          ToastOpen({
            message: t(
              'You do not have the necessary rights to delete folders in this project.',
            ),
            type: 'error',
          })
        },
        NO_ADMIN_RIGHTS: () => {
          ToastOpen({
            message: t("You can't delete a root folder."),
            type: 'error',
          })
        },
        FETCH_ERROR: () => {
          ToastOpen({
            message: t('The drive service is not available at the moment.'),
            type: 'error',
            duration: 50,
          })
        },
        unknown_error: () => {
          ToastOpen({
            message: t('Error deleting folder.'),
            type: 'error',
          })
        },
      },
      () => {
        dispatch(setFolderToDelete(undefined))
        dispatch(idleDeleteFolderStatus())
      },
    ],
  ])

  /**
   *
   */
  function confirmDeleteFolder() {
    if (folderToDelete && selectedDriveFolder) {
      dispatch(
        deleteFolder({
          driveId: selectedDriveFolder.driveId,
          folderId: folderToDelete.folder.id,
          mainFolder: folderToDelete.mainFolder,
        }),
      )
    }
  }

  return (
    <Modal
      centered
      open={folderToDelete != undefined}
      title={t('Delete folder')}
      onOk={confirmDeleteFolder}
      onCancel={() => dispatch(setFolderToDelete(undefined))}
      okText={t('Yes', { ns: 'common' })}
      cancelText={t('No', { ns: 'common' })}
    >
      <p>
        <Trans
          ns="drives"
          i18nKey="DELETE_FOLDER"
          shouldUnescape={true}
          values={{
            name: limitText(folderToDelete?.folder.name),
          }}
          components={{ bold: <strong /> }}
        />
      </p>
    </Modal>
  )
}

export default DeleteFolderModal
