import React from 'react'
import { Modal } from 'antd'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'
import {
  deleteDrive,
  idleDeleteDriveStatus,
  setDriveToDelete,
  setDriveToModify,
} from '../../../features/Drives/redux/drivesSlice'
import { unescape } from 'lodash'
import { limitText } from '../../../utils/Utils'
import { useAttemptsListener } from 'auxasphere-react-kit'
import { useToastContext } from '../../Toast/ToastContext'

export default function DeleteDriveModal() {
  const { t } = useTranslation('drives')
  const dispatch = useDispatch<AppDispatch>()
  const driveToDelete = useSelector((state: RootState) => state.drive.driveToDelete)
  const deleteDriveStatus = useSelector(
    (state: RootState) => state.drive.deleteDriveStatus,
  )
  const { ToastOpen } = useToastContext()

  useAttemptsListener([
    [
      deleteDriveStatus,
      {
        success: () => {
          dispatch(setDriveToDelete(undefined))
          dispatch(setDriveToModify(undefined))
          ToastOpen({
            message: t('Drive was successfully deleted.'),
            type: 'success',
          })
          dispatch(idleDeleteDriveStatus())
        },
        error: () =>
          ToastOpen({
            message: t('Error deleting drive.'),
            type: 'error',
          }),
      },
    ],
  ])

  return (
    <Modal
      centered
      title={t('Delete drive')}
      open={!!driveToDelete}
      onOk={() => dispatch(deleteDrive())}
      okText={t('Yes', { ns: 'common' })}
      confirmLoading={deleteDriveStatus === 'loading'}
      onCancel={() => dispatch(setDriveToDelete(undefined))}
      cancelText={t('No', { ns: 'common' })}
    >
      <p>
        <Trans
          i18nKey="DELETE_DRIVE"
          shouldUnescape={true}
          ns="drives"
          values={{
            name: unescape(limitText(driveToDelete?.name)),
          }}
          components={{ bold: <strong /> }}
        />
      </p>
    </Modal>
  )
}
