import React, { useEffect, useState } from 'react'
import { Breadcrumb } from 'antd'
import { AiFillHome } from 'react-icons/ai'
import { BiDotsHorizontalRounded } from 'react-icons/bi'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { FolderInterface } from '../../../features/Drives/redux/drivesSlice'
import {
  BreadcrumbItemType,
  BreadcrumbSeparatorType,
  ItemType,
} from 'antd/es/breadcrumb/Breadcrumb'
import { useNavigate } from 'react-router-dom'

function DrivesBreadcrumb() {
  const [breadcrumbItems, setBreadcrumbItems] = useState<ItemType[]>([])
  const selectedDriveFolder = useSelector(
    (state: RootState) => state.drive.selectedDriveFolder,
  )
  const navigate = useNavigate()
  const TAIL_BREADCRUMB_SIZE = 3

  useEffect(() => {
    if (selectedDriveFolder?.breadcrumb) {
      const breadcrumbList: ItemType[] = selectedDriveFolder.breadcrumb.map((item) => ({
        title: <a>{item.name}</a>,
        key: item.id,
        onClick: () => handleBreadcrumbClick(item.id, selectedDriveFolder.driveId),
      }))

      const menuItems =
        selectedDriveFolder.childrenFolders &&
        selectedDriveFolder.childrenFolders.length > 0
          ? buildBreadcrumbDropDownFromFolder(selectedDriveFolder.childrenFolders)
          : undefined

      breadcrumbList.push({
        title: <a>{selectedDriveFolder.name}</a>,
        key: selectedDriveFolder.id,
        menu: menuItems ? { items: menuItems } : undefined,
        onClick: () =>
          handleBreadcrumbClick(selectedDriveFolder.id, selectedDriveFolder.driveId),
      })

      if (breadcrumbList.length > TAIL_BREADCRUMB_SIZE) {
        const hiddenItems = breadcrumbList.slice(
          0,
          breadcrumbList.length - TAIL_BREADCRUMB_SIZE,
        )
        const visibleItems = breadcrumbList.slice(
          breadcrumbList.length - TAIL_BREADCRUMB_SIZE,
        )

        setBreadcrumbItems([
          {
            title: <BiDotsHorizontalRounded size="1.5em" />,
            key: 'hidden',
            menu: { items: buildBreadcrumbDropDown(hiddenItems) },
            className: 'breadcrumb-dropdown',
          } as ItemType,
          ...visibleItems,
        ])
      } else {
        setBreadcrumbItems(breadcrumbList)
      }
    }
  }, [selectedDriveFolder])

  /**
   *
   * @param folderId
   * @param driveId
   */
  const handleBreadcrumbClick = async (folderId: string, driveId?: string) => {
    navigate(`/drives/${driveId}/folder/${folderId}`)
  }

  /**
   * Build dropdown for selectedDriveFolder
   * @param items
   * @returns
   */
  const buildBreadcrumbDropDownFromFolder = (items: FolderInterface[]): ItemType[] => {
    return items.map((item) => ({
      key: item.id,
      label: <a>{item.name}</a>,
      onClick: () => handleBreadcrumbClick(item.id, selectedDriveFolder?.driveId),
    }))
  }

  /**
   * Build dropdown from Hide items
   * @param items
   * @returns
   */
  function buildBreadcrumbDropDown(
    items: Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[],
  ) {
    return items.map((item) => {
      return {
        key: item.key,
        label: item.title,
        className: item.className,
        onClick: item.onClick,
      }
    })
  }

  return (
    <div className="Drives-breadcrumb-container">
      <AiFillHome size="1.1rem" />
      <Breadcrumb items={breadcrumbItems} separator="/" />
    </div>
  )
}

export default DrivesBreadcrumb
