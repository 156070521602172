import React from 'react'
import { Table } from 'antd'
import { SorterResult, TableRowSelection } from 'antd/es/table/interface'
import { useTranslation } from 'react-i18next'
import ParticipantsPagination from '../ParticipantsPagination'
import { GroupParticipant, SortableField } from '../redux/groupsSlice'
import './AddUserGroup.scss'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { TableProps } from 'antd/lib'
import { ColumnsType } from 'antd/lib/table'
import useGroupParticipantsTableSorter from '../../../utils/hooks/useGroupParticipantsTableSorter'
import useParticipantsTableSorter from '../../../utils/hooks/useParticipantsTableSorter'

interface Props {
  participantsToBeDisplayed: GroupParticipant[]
  onChange: (
    selectedParticipantKeys: React.Key[],
    selectedParticipants: GroupParticipant[],
  ) => void
  defaultSelectedParticipants: string[]
  immutableParticipants?: string[]
}

function AddUserGroup({
  participantsToBeDisplayed,
  onChange,
  defaultSelectedParticipants,
  immutableParticipants,
}: Props) {
  const { t } = useTranslation('groups')

  const readonly = useSelector((state: RootState) => state.groups.groupToModify?.readonly)

  const groupId = useSelector((state: RootState) => state.groups.groupToModify?.group.id)

  const { setGroupTableState } = useGroupParticipantsTableSorter({ groupId })

  const { setTableState } = useParticipantsTableSorter({})

  const handleSorterChange: TableProps<GroupParticipant>['onChange'] = (
    pagination,
    filters,
    sorter,
  ) => {
    const { order, columnKey } = sorter as SorterResult<GroupParticipant>
    setGroupTableState({
      sortField: columnKey as SortableField,
      sortOrder: order ?? 'ascend',
    })
    setTableState((prev) => ({
      ...prev,
      sortField: columnKey as SortableField,
      sortOrder: order ?? 'ascend',
    }))
  }

  const columns: ColumnsType<GroupParticipant> = [
    {
      title: t('Last name', { ns: 'common' }),
      className: 'name-column',
      render: (p: GroupParticipant) => {
        return <>{`${p.lastName}`}</>
      },
      key: 'last_name',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'ascend',
    },
    {
      title: t('First name', { ns: 'common' }),
      className: 'name-column',
      render: (p: GroupParticipant) => {
        return <>{`${p.firstName}`}</>
      },
      key: 'first_name',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: t('Email', { ns: 'common' }),
      className: 'name-column',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
  ]

  const rowSelection: TableRowSelection<GroupParticipant> = {
    type: 'checkbox',
    preserveSelectedRowKeys: true,
    onChange: onChange, //handleSelectionChanged,
    getCheckboxProps: (participant: GroupParticipant) => ({
      name: participant.email,
      disabled: immutableParticipants?.includes(participant.email),
    }),
    defaultSelectedRowKeys: defaultSelectedParticipants,
    // selections: true, //TODO: discuss with Cedric (we could make custom selection https://ant.design/components/table#selection:~:text=5-,Custom%20selection,-Use%20rowSelection.selections)
  }

  return (
    <div className="AddUserGroup">
      <Table
        className="table"
        dataSource={participantsToBeDisplayed}
        rowSelection={!readonly ? rowSelection : undefined}
        // loading={loading}
        rowKey={(record) => record.email}
        columns={columns}
        pagination={
          !readonly ||
          (participantsToBeDisplayed && participantsToBeDisplayed?.length <= 8)
            ? false
            : { position: ['bottomCenter'] }
        }
        onChange={handleSorterChange}
        //group participants are already fetched
        // onChange={(changedValues, values) => {console.log('CHANGEchangedValues, values:', changedValues, values)}}
      />
      {!readonly && <ParticipantsPagination />}
    </div>
  )
}

export default AddUserGroup
