import React, { useEffect, useMemo, useState } from 'react'
import Layout from '../../components/Layout/Layout'
import { useTranslation } from 'react-i18next'
import './drives.scss'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { fetchDrives, FolderInterface, idleDrive } from './redux/drivesSlice'
import DrivesMenu from '../../components/DrivesComponent/DrivesMenu/DrivesMenu'
import { Drawer } from 'antd'
import DrivesContent from '../../components/DrivesComponent/DrivesContent'
import DrivesSearch from '../../components/DrivesComponent/DrivesSearch'

interface Props {
  home?: true
}

function Drives({ home }: Props) {
  const dispatch = useDispatch<AppDispatch>()
  const drives = useSelector((state: RootState) => state.drive.drives)

  const [openDrawerMenu, setOpenDrawerMenu] = useState(false)
  const email = useSelector((state: RootState) => state.auth.email)

  /**
   *
   */
  useEffect(() => {
    dispatch(fetchDrives({}))
    return () => {
      dispatch(idleDrive())
    }
  }, [])

  const { adminRootFolders, sharedRootFolders } = useMemo(() => {
    let adminFolders: FolderInterface[] = []
    let sharedFolders: FolderInterface[] = []

    drives?.forEach((drive) => {
      const isCreator = drive.creatorEmail === email

      if (isCreator) {
        adminFolders.push(drive.rootFolder)
      } else {
        sharedFolders.push(drive.rootFolder)
      }
    })

    return {
      adminRootFolders: adminFolders,
      sharedRootFolders: sharedFolders,
    }
  }, [drives])

  return (
    <div className="Drives">
      <Layout
        title="Drive"
        headerTitle={<DriveLayoutHeader home={home} />}
        menu={
          <DrivesMenu
            adminRootFolders={adminRootFolders}
            sharedRootFolders={sharedRootFolders}
          />
        }
        content={
          <DrivesContent
            adminRootFolders={adminRootFolders}
            sharedRootFolders={sharedRootFolders}
            home={home}
          />
        }
      />
      <Drawer
        title="Drive"
        open={openDrawerMenu}
        placement="left"
        className="right-side-drawer"
        onClose={() => setOpenDrawerMenu(false)}
      >
        <DrivesMenu
          adminRootFolders={adminRootFolders}
          sharedRootFolders={sharedRootFolders}
        />
      </Drawer>
    </div>
  )
}

export default Drives

interface HeaderProps {
  home?: boolean
}

function DriveLayoutHeader({ home }: HeaderProps) {
  return (
    <div className="d-flex d-flex-center d-flex-middle g-1rem">
      <h1 className="title">Drive</h1>
      {!home && (
        <div className="search-header-container">
          <DrivesSearch />
        </div>
      )}
    </div>
  )
}
