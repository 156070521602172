import React, { useCallback, useEffect, useState } from 'react'
import { Divider } from 'antd'
import { FolderInterface } from '../../../../features/Drives/redux/drivesSlice'
import DriveTreeItem from './DriveTreeItem'

interface Props {
  drivesFolders: FolderInterface[]
  selectedFolder: FolderInterface | undefined
  onSelectFolder: (folder: FolderInterface) => void
  expandedKeys: React.Key[]
  setExpandedKeys: (key: React.Key[]) => void
  manageDrive?: boolean
}

export interface FolderTreeData {
  key: React.Key
  title: string
  children: any
  createdAt: string
  driveId: string
  root?: boolean
}

/**
 *
 * @param param0
 * @returns
 */
function DrivesTree({
  drivesFolders,
  selectedFolder,
  onSelectFolder,
  expandedKeys,
  setExpandedKeys,
  manageDrive = false,
}: Props) {
  const [treeDriveFolders, setTreeDriveFolders] = useState<FolderTreeData[]>([])

  /**
   *
   */
  useEffect(() => {
    const treeDrivesData = setDrivesTreeMemoized(drivesFolders)
    if (treeDrivesData) {
      setTreeDriveFolders(treeDrivesData)
    }
  }, [drivesFolders])

  /**
   *
   */
  const setDrivesTreeMemoized = useCallback(
    (driveFolders: FolderInterface[]): FolderTreeData[] | undefined => {
      if (driveFolders) {
        return driveFolders.map((driveFolder: FolderInterface) => {
          const childrenNodes = setDrivesTreeMemoized(driveFolder.childrenFolders || [])

          return {
            key: driveFolder.id,
            title: driveFolder.name,
            children: childrenNodes,
            createdAt: driveFolder.createdAt?.toString(),
            driveId: driveFolder.driveId,
            root: driveFolder.parentId === null,
          }
        })
      }
    },
    [],
  )

  return (
    <>
      {treeDriveFolders.map((folder: FolderTreeData, index) => (
        <div key={folder.key}>
          <DriveTreeItem
            folder={folder}
            drivesFolders={drivesFolders}
            manageDrive={manageDrive}
            selectedFolder={selectedFolder}
            onSelectFolder={onSelectFolder}
            expandedKeys={expandedKeys}
            setExpandedKeys={setExpandedKeys}
          />
          {index !== treeDriveFolders.length - 1 && <Divider className="m-0" />}
        </div>
      ))}
    </>
  )
}

export default DrivesTree
