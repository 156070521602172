import React from 'react'
import { Pagination } from 'antd'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import useParticipantsTableSorter from '../../utils/hooks/useParticipantsTableSorter'

export default function ParticipantsPagination() {
  const organisation = useSelector((state: RootState) => state.organisation)

  const { tableState, setTableState, updateTable } = useParticipantsTableSorter({
    usersPerPage: 8,
  })

  if (organisation.usersTotalNumber > 8)
    return (
      <Pagination
        style={{ alignSelf: 'center', justifyContent: 'center' }}
        showTotal={(total, range) => (
          <Trans
            ns="common"
            i18nKey="TABLE_PAGINATION"
            values={{
              range0: range[0],
              range1: range[1],
              total: total,
            }}
          />
        )}
        className="mt-1rem"
        onChange={(page, pageSize) =>
          setTableState({
            usersPerPage: pageSize,
            page: page,
            sortField: tableState.sortField,
            sortOrder: tableState.sortOrder,
          })
        }
        onShowSizeChange={(page, pageSize) =>
          setTableState({
            usersPerPage: pageSize,
            page: page,
            sortField: tableState.sortField,
            sortOrder: tableState.sortOrder,
          })
        }
        current={tableState.page}
        pageSize={tableState.usersPerPage}
        total={organisation.usersTotalNumber}
        showSizeChanger={true}
        pageSizeOptions={[8, 10, 20, 50, 100]}
      />
    )
  else return <></>
}
