import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router'
import { RootState } from '../../store'
import { ServicePermission, hasPermission } from './ServicePermission'
function RequireAuth({
  children,
  invitedAccepted,
  requiredServicePermission,
}: {
  children: any
  invitedAccepted?: boolean
  requiredServicePermission?: ServicePermission[]
}) {
  const username = useSelector(
    (state: RootState) => state.auth.jwt || (invitedAccepted && state.invite.jwt),
  )
  const jwt = useSelector((state: RootState) => state.auth.jwt)
  const access = useSelector((state: RootState) => state.auth.access)

  // If there is no JWT or invite's token
  if (!username) {
    return <Navigate to="/login" />
  }

  if (
    jwt &&
    access &&
    requiredServicePermission &&
    !hasPermission(access, requiredServicePermission)
  ) {
    return <Navigate to="/applications?redirected=true" />
  }

  return children
}

export default RequireAuth
