import React from 'react'
import { Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { useIsMobile } from '../../utils/hooks/useIsMobile'
import classNames from 'classnames'
import { GoFile } from 'react-icons/go'

interface Props {
  documentDrawerOpen: boolean
  toggleDrawerDocuments: () => void
}

function CallControlsDocuments({ documentDrawerOpen, toggleDrawerDocuments }: Props) {
  const { t } = useTranslation('room')
  const isMobile = useIsMobile()

  return (
    <Tooltip title={t('Documents', { ns: 'common' })}>
      <div
        className={classNames({
          'call-control': !isMobile,
          'call-control-dropdown': isMobile,
          'call-control--active': documentDrawerOpen,
        })}
        onClick={toggleDrawerDocuments}
      >
        <GoFile size="1.8rem" color="#ffffff" />
      </div>
    </Tooltip>
  )
}

export default CallControlsDocuments
