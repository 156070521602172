import React, { useRef } from 'react'
import { Modal, Form, Input, InputRef } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import {
  setOpenCreateFolder,
  createFolder,
  idleCreateFolderStatus,
  setFolderToDelete,
} from '../../features/Drives/redux/drivesSlice'
import { useTranslation } from 'react-i18next'
import { useAttemptsListener } from 'auxasphere-react-kit'
import { useToastContext } from '../Toast/ToastContext'

function CreateFolderModal() {
  const dispatch = useDispatch<AppDispatch>()
  const { t } = useTranslation('drives')
  const inputRef = useRef<InputRef>(null)
  const [folderForm] = Form.useForm()
  const openCreateFolderModal = useSelector(
    (state: RootState) => state.drive.openCreateFolder,
  )
  const selectedDriveFolder = useSelector(
    (state: RootState) => state.drive.selectedDriveFolder,
  )
  const createFolderStatus = useSelector(
    (state: RootState) => state.drive.createFolderStatus,
  )
  const { ToastOpen } = useToastContext()

  useAttemptsListener([
    [
      createFolderStatus,
      {
        success: () => {
          ToastOpen({
            message: t('Folder was successfully created.'),
            type: 'success',
          })
          onClose()
        },
        NOT_ENOUGH_FOLDER_RIGHT: () => {
          ToastOpen({
            message: t(
              'You do not have the necessary rights to create folders in this project.',
            ),
            type: 'error',
          })
          onClose()
        },
        FOLDER_ALREADY_EXIST: () => {
          ToastOpen({
            message: t('Unable to create the folder because this name already exists.'),
            type: 'error',
          })
        },
        FETCH_ERROR: () => {
          ToastOpen({
            message: t('The drive service is not available at the moment.'),
            type: 'error',
            duration: 50,
          })
        },
        unknown_error: () => {
          ToastOpen({
            message: t('Error creating folder.'),
            type: 'error',
          })
        },
      },
      () => dispatch(idleCreateFolderStatus()),
    ],
  ])

  /**
   *
   */
  function onCreateFolder() {
    if (selectedDriveFolder) {
      dispatch(
        createFolder({
          parentId: selectedDriveFolder.id,
          name: folderForm.getFieldsValue().name,
          driveId: selectedDriveFolder.driveId,
        }),
      )
    }
  }

  /**
   *
   */
  function onClose() {
    dispatch(setOpenCreateFolder(false))
    folderForm.resetFields()
  }

  return (
    <Modal
      centered
      open={openCreateFolderModal}
      title={t('Create folder')}
      onOk={folderForm.submit}
      onCancel={onClose}
      okText={t('Create', { ns: 'common' })}
      cancelText={t('Cancel', { ns: 'common' })}
      afterOpenChange={(open) => open && inputRef.current?.focus()}
    >
      <div className="modal-content-container">
        <Form
          name="basic"
          layout="vertical"
          autoComplete="off"
          form={folderForm}
          onFinish={onCreateFolder}
        >
          <Form.Item
            className="mb-0"
            name="name"
            label={t('Folder name')}
            rules={[{ required: true, message: t('Please input folder name') }]}
          >
            <Input ref={inputRef} />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  )
}

export default CreateFolderModal
