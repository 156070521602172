import React, { useEffect, useRef, useState } from 'react'
import { Modal, Input, List, InputRef } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { AppDispatch, RootState } from '../../../../store'
import { getChatDetails } from '../../../../features/room/RoomChat/ChatLibrary'
import { normalizeString } from '../chatService'
import { setCurrentChatId, setMessageInputDraftText } from '../chatsSlice'
import { setShareToTeamUrl } from '../../../../features/Drives/redux/drivesSlice'
import VirtualList from 'rc-virtual-list'

interface Props {
  squadId: string
}

function ShareDriveToTeamModal({ squadId }: Props) {
  const { t } = useTranslation('chat')
  const dispatch = useDispatch<AppDispatch>()
  const chats = useSelector((state: RootState) => state.chats.chats[squadId]) || []
  const shareToTeamUrl = useSelector((state: RootState) => state.drive.shareToTeamUrl)
  const [searchQuery, setSearchQuery] = useState('')
  const [shareDriveToTeamModalOpened, setShareDriveToTeamModalOpened] = useState(false)
  const inputTitleRef = useRef<InputRef>(null)

  useEffect(() => {
    if (shareToTeamUrl) {
      setShareDriveToTeamModalOpened(true)
    }
  }, [shareToTeamUrl])

  const filteredChats = chats.filter((c) => {
    const { name } = getChatDetails(c)
    const qNormalized = normalizeString(searchQuery)
    const nameNormalized = normalizeString(name)
    return nameNormalized.includes(qNormalized) && !c.archived
  })

  const handleChatSelect = (chatId: string) => {
    dispatch(setCurrentChatId(chatId))

    if (shareToTeamUrl) {
      setShareDriveToTeamModalOpened(false)
    }
  }

  /**
   *
   */
  function onClose() {
    setShareDriveToTeamModalOpened(false)
    dispatch(setShareToTeamUrl(undefined))
  }

  return (
    <Modal
      title={t('Share folder to a conversation')}
      open={shareDriveToTeamModalOpened}
      onCancel={onClose}
      centered
      width="50em"
      okButtonProps={{ style: { display: 'none' } }}
      afterOpenChange={(open) => open && inputTitleRef.current?.focus()}
    >
      <div className="chat-modal">
        <Input
          className="mb-1em"
          placeholder={t('Search a conversation')}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          allowClear
          ref={inputTitleRef}
        />
        <List className="select_chat_list virtual-list-participant">
          <VirtualList data={filteredChats} itemKey="id">
            {(chat) => {
              const { name, icon, backgroundColor } = getChatDetails(chat)
              return (
                <List.Item key={chat.id} onClick={() => handleChatSelect(chat.id)}>
                  <List.Item.Meta
                    avatar={
                      <div
                        className="chat_icon chat_icon--large"
                        style={{ backgroundColor }}
                      >
                        {icon}
                      </div>
                    }
                    title={name}
                    description={
                      chat.peer?.firstName && chat.peer?.lastName
                        ? chat.peer?.email
                        : t('Groups', { ns: 'common' })
                    }
                  />
                </List.Item>
              )
            }}
          </VirtualList>
        </List>
      </div>
    </Modal>
  )
}

export default ShareDriveToTeamModal
