import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import {
  SortableField,
  fetchOrganizationSummary,
  fetchUsers,
} from '../../features/Organisation/redux/organisationSlice'
import { SortOrder } from 'antd/es/table/interface'

interface ParticipantsTableState {
  page: number
  usersPerPage: number
  sortField: SortableField
  sortOrder: SortOrder
}

type UseParticipantsTableSorterParams = Partial<ParticipantsTableState>

const DEFAULT_PAGINATION: ParticipantsTableState = {
  page: 1,
  usersPerPage: 20,
  sortField: SortableField.LAST_NAME,
  sortOrder: 'ascend',
}

export default function useParticipantsTableSorter({
  page = DEFAULT_PAGINATION.page,
  usersPerPage = DEFAULT_PAGINATION.usersPerPage,
  sortField = DEFAULT_PAGINATION.sortField,
  sortOrder = DEFAULT_PAGINATION.sortOrder,
}: UseParticipantsTableSorterParams) {
  const dispatch = useDispatch<AppDispatch>()
  const organisation = useSelector((state: RootState) => state.organisation)

  const [tableState, setTableState] = useState<ParticipantsTableState>({
    page,
    usersPerPage,
    sortField,
    sortOrder,
  })

  const updateTable = () => {
    const { page, usersPerPage, sortField, sortOrder } = tableState
    dispatch(
      fetchUsers({
        page,
        usersPerPage,
        sortField,
        sortOrder,
      }),
    )
    if (organisation.organisation) {
      dispatch(fetchOrganizationSummary({ organizationId: organisation.organisation.id }))
    }
  }

  useEffect(() => {
    updateTable()
  }, [tableState])

  return {
    tableState,
    setTableState,
    updateTable,
  }
}
